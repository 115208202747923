import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import ENV from '../ENV';
import BillingPayment from './Billing_payment';

const Billing = ({ onclickedpatient }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [trxlist, setTrxList] = useState([])
    const [trxdetails, setTrxDetails] = useState(0)

    useMemo(async () => {

        var formData = new FormData();
        formData.append("transaction_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setTrxList(res.data)
        })
    }, [ENV])

    const reloadData = async () => {
        var formData = new FormData();
        formData.append("transaction_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setTrxList(res.data)
        })
    }


    const billingFiltered = trxlist.filter((i) => {
        return i.patient_id === onclickedpatient.patient_id;
    });

    useEffect(() => {
        if (trxdetails.patient_id !== onclickedpatient.patient_id) {
            setTrxDetails(0)
        }
    }, [trxdetails, onclickedpatient])

    // console.log(billingFiltered)
    useEffect(() => {

        window.addEventListener("resize", () => setWindowWidth(window.innerWidth)); //const [windowWidth, setWindowWidth] = useState(window.innerWidth)
        return () => window.removeEventListener("resize", () => setWindowWidth(window.innerWidth));
    }, [])

    return (
        <div className='row'>
            <div className={windowWidth === 1024 ? "col-sm-12" : windowWidth === 768 ? "col-sm-12" : 'col-sm-6'}>
                <div className='hidescroll' style={{ overflowY: "auto", height: windowWidth === 1024 ? null : windowWidth === 768 ? null :"50vh" }}>
                {
                        billingFiltered.map((i, k) => {
                            return (
                                <div key={k} style={{ width: "100%", padding: 5, borderRadius: 0, border: "1px solid #ddd" }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <span>{i.date_time}</span>
                                        {
                                            i.method === 'Free' ?
                                                <span style={{ fontWeight: 600 }}>{"FREE"}</span>
                                                : null
                                        }
                                        {
                                            i.method === 'Insurance' ?
                                                <span style={{ fontWeight: 600 }}>{"INSURANCE"}</span>
                                                : null
                                        }
                                        {
                                            i.method === 'Input Amount' ?
                                                <>
                                                    {
                                                        i.amount === '0.00' ?
                                                            <span style={{ fontWeight: 600 }}>{"FREE"}</span>
                                                            :
                                                            <span style={{ fontWeight: 600 }}>Amount: {i.amount}</span>
                                                    }

                                                </>
                                                : null
                                        }
                                        {
                                            trxdetails.transaction_id !== i.transaction_id ?
                                                <>
                                                    {
                                                        i.status === 'paid' ?
                                                            <>
                                                                <button onClick={(e) => setTrxDetails(i)} style={{ width: 150, borderRadius: 5, border: "2px solid #fff", fontWeight: 600, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null, backgroundColor: "rgb(138 147 127)", color: "#fff", boxShadow: "-1px 2px 5px grey" }}>View</button>
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    i.method === 'Free' ?
                                                                        <button onClick={(e) => setTrxDetails(i)} style={{ width: 150, borderRadius: 5, border: "2px solid #fff", fontWeight: 600, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null, backgroundColor: "rgb(138 147 127)", color: "#fff", boxShadow: "-1px 2px 5px grey" }}>View</button>
                                                                        : null
                                                                }
                                                                {
                                                                    i.method === 'Insurance' ?
                                                                        <button onClick={(e) => setTrxDetails(i)} style={{ width: 150, borderRadius: 5, border: "2px solid #fff", fontWeight: 600, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null, backgroundColor: "rgb(138 147 127)", color: "#fff", boxShadow: "-1px 2px 5px grey" }}>View</button>
                                                                        : null
                                                                }
                                                                {
                                                                    i.method === 'Input Amount' ?
                                                                        <button onClick={(e) => setTrxDetails(i)} style={{ width: 150, borderRadius: 5, border: "2px solid #fff", fontWeight: 600, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null, backgroundColor: "rgb(67, 190, 160)", color: "#fff", boxShadow: "-1px 2px 5px grey" }}>Pending Payment</button>
                                                                        : null
                                                                }
                                                            </>
                                                    }
                                                </>
                                                :
                                                <button style={{ width: 150, borderRadius: 5, border: "2px solid #fff", fontWeight: 600, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null, backgroundColor: "rgb(170 190 67)", color: "#fff", boxShadow: "-1px 2px 5px grey" }}>Selected</button>
                                        }
                                    </div>
                                    {/* </div> */}
                                    {
                                        (windowWidth === 1024 || windowWidth === 768) && trxdetails.transaction_id === i.transaction_id ?
                                        <>
                                            {
                                                trxdetails.patient_id === onclickedpatient.patient_id ?
                                                    <BillingPayment onclickedpatient={onclickedpatient} trxdetails={trxdetails} setTrxDetails={setTrxDetails} reloadData={reloadData} />
                                                    : null
                                            }
                                        </>
                                        :null
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {
                windowWidth !== 1024 && windowWidth !== 768 ?
                (
                    trxdetails.patient_id === onclickedpatient.patient_id ?
                        <BillingPayment onclickedpatient={onclickedpatient} trxdetails={trxdetails} setTrxDetails={setTrxDetails} reloadData={reloadData} />
                        : null
                ):null
            }
        </div>
    );
}

export default Billing;
