import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import ENV from '../ENV';
import { Image } from 'primereact/image';

const ChartingImageIcon = ({ onclickedpatient, items }) => {
    const [open, setOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    useEffect(() => {

        window.addEventListener("resize", () => setWindowWidth(window.innerWidth)); //const [windowWidth, setWindowWidth] = useState(window.innerWidth)
        return () => window.removeEventListener("resize", () => setWindowWidth(window.innerWidth));
    }, [])
    return (
        <>
            <Dialog maximized={false} blockScroll={true} position={"top"} draggable={false} visible={open} header="Preview" headerStyle={{ padding: 5, textAlign: "center" }} style={{ width: windowWidth === 1024 ? "80vw" : windowWidth === 768 ? "100vw" : '30vw', overflow: "hidden", overflowY: "auto", }} onHide={() => setOpen(false)}>
                <div style={{display:"flex", flexDirection:"row", flexWrap: "wrap", gap: 5}}>
                    {

                        JSON.parse(items?.images)?.map((file, fk) => {
                            return (
                                <Image src={ENV.DOMAIN + 'files/'+onclickedpatient.fullname.trim()+'/'+file.name} alt="Image" width="250" preview />
                                // <img src={ENV.DOMAIN + 'files/'+onclickedpatient.fullname.trim()+'/'+file.name} style={{ width: JSON.parse(items?.images)?.length > 2 ? "45%" : "100%", height: JSON.parse(items?.images)?.length > 2 ? "45%" : "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                            )
                        })
                    }
                </div>
                
                
                
            </Dialog>
            <div onClick={
                () => setOpen(true)
            }
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                    border: "1px solid #ddd", borderRadius: 5,
                    padding: 2,
                    fontSize: 15,
                    cursor: "pointer"
                }}>
                <span className='pi pi-image'></span> 
                {JSON.parse(items?.images)?.length <= 1 ? "View Image" : JSON.parse(items?.images)?.length+' image files'}
            </div>
        </>
    );
}

export default ChartingImageIcon;
