import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useStateValue } from '../StateProvider';
import { initialState, reducer } from '../reducer';
import AppContext from '../context/AppContext';

const CreateMedicalCertificate = ({ onclickedpatient, onConsultationButton }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    if(localStorage.getItem('medcert') !=null) {
        var medcert = localStorage.getItem('medcert');
        // console.log(1)
    } else {
        var medcert = JSON.stringify([]);
        // console.log(2)
    }
    
    const [medCertData, setMedCertData] = useState(JSON.parse(medcert))
    
    const medcertdata = medCertData.filter((i) => {
        return i.patient_id === onclickedpatient.patient_id;
    });

    const index = medCertData?.findIndex(i=>i?.patient_id == onclickedpatient?.patient_id);

    const [inputform, setInputForm] = useState({
        "patient_id": onclickedpatient?.patient_id,
        "purpose": medCertData[index]?.purpose ?? '',
        "require": medCertData[index]?.require ?? '',
        "diagnosis": medCertData[index]?.diagnosis ?? '',
        "remarks": medCertData[index]?.remarks ?? '',
        "date_request": medCertData[index]?.date_request ?? ''
    })

    const onSave = async () => {
        if (inputform.purpose != '' || inputform.require != '' || inputform.diagnosis != '' || inputform.remarks != '' || inputform.date_request != '') {
            if(medcertdata.length > 0) {
                medCertData[index].purpose = inputform.purpose;
                medCertData[index].require = inputform.require;
                medCertData[index].diagnosis = inputform.diagnosis; //String(inputform.diagnosis).replace(/\s/g, '\n');
                medCertData[index].remarks = inputform.remarks; //String(inputform.remarks).replace(/\s/g, '\n');
                medCertData[index].date_request = inputform.date_request;
                localStorage.setItem("medcert", JSON.stringify(medCertData))
            } else {
                const data = {
                    "patient_id": onclickedpatient?.patient_id,
                    "purpose": inputform.purpose,
                    "require": inputform.require,
                    "diagnosis": inputform.diagnosis, //String(inputform.diagnosis).replace(/\s/g, '\n'),
                    "remarks": inputform.remarks, //String(inputform.remarks).replace(/\s/g, '\n'),
                    "date_request": inputform.date_request
                }
                localStorage.setItem("medcert", JSON.stringify([...medCertData, data]))
            }
            onConsultationButton('')
        }
    }

    const clear = () => {
        const medcertdata = medCertData.filter((i) => {
            return i.patient_id !== onclickedpatient.patient_id;
        });
        setInputForm({
            "patient_id": '',
            "require": '',
            "purpose": '',
            "diagnosis": '',
            "remarks": '',
            "date_request": ''
        })
        localStorage.setItem("medcert", JSON.stringify(medcertdata))
    }

    // console.log(inputform)
    useEffect(() => {

        window.addEventListener("resize", () => setWindowWidth(window.innerWidth)); //const [windowWidth, setWindowWidth] = useState(window.innerWidth)
        return () => window.removeEventListener("resize", () => setWindowWidth(window.innerWidth));
    }, [])

    return (
        <div>
            <div style={{ padding: 5, backgroundColor: "#fff", height: "85vh" }}>
                <div style={{ padding: 5 }}>
                    <span style={{ fontSize: 25, fontWeight: 600 }}>Issue Medical Certificate</span>
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                    <div style={{ width: windowWidth === 1024 ? "80%" : windowWidth === 768 ? "80%" : "50%" }}>
                        <span style={{ fontWeight: 600 }}>Certificate Details</span>
                    </div>
                    <div style={{ width: windowWidth === 1024 ? "80%" : windowWidth === 768 ? "80%" : "50%" }}>
                        <div>
                            <span style={{ fontWeight: 600 }}>Purpose</span>
                            <div>
                                <input name="purpose" onChange={(e) => setInputForm({...inputform, [e.target.name]: e.target.value})} value={inputform.purpose} style={{ padding: 5, width: "100%", resize: "none", borderRadius: 5, border: "1px solid #ddd" }} />
                            </div>
                        </div>
                        <div>
                            <span style={{ fontWeight: 600 }}>Requested By</span>
                            <div>
                                <input name="require" onChange={(e) => setInputForm({...inputform, [e.target.name]: e.target.value})} value={inputform.require} style={{ padding: 5, width: "100%", resize: "none", borderRadius: 5, border: "1px solid #ddd" }} />
                            </div>
                        </div>
                        <div>
                            <span style={{ fontWeight: 600 }}>Diagnosis</span>
                            <div>
                                <textarea name="diagnosis" onChange={(e) => setInputForm({...inputform, [e.target.name]: e.target.value})} value={inputform.diagnosis} style={{ padding: 5, width: "100%", height: 100, resize: "none", borderRadius: 5, border: "1px solid #ddd" }} />
                            </div>
                        </div>
                        <div>
                            <span style={{ fontWeight: 600 }}>Remarks</span>
                            <div>
                                <textarea name="remarks" onChange={(e) => setInputForm({...inputform, [e.target.name]: e.target.value})} value={inputform.remarks} style={{ padding: 5, width: "100%", height: 100, resize: "none", borderRadius: 5, border: "1px solid #ddd" }} />
                            </div>
                        </div>
                        <div>
                            <span style={{ fontWeight: 600 }}>Date</span>
                            <div>
                                <input type='date' name="date_request" onChange={(e) => setInputForm({...inputform, [e.target.name]: e.target.value})} value={inputform.date_request} style={{ padding: 5, width: "100%", resize: "none", borderRadius: 5, border: "1px solid #ddd" }} />
                            </div>
                        </div>
                        <br />
                        <div style={{display: "flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                <button onClick={() => onConsultationButton('')} style={{ width: 100, height: 40, borderRadius: 5, backgroundColor: "red", border: "3px solid #ddd", color: "#fff", boxShadow: "-1px 2px 5px grey" }}>Back</button>
                                {
                                    inputform.purpose!='' || inputform.diagnosis!='' || inputform.remarks!='' ?
                                        <button onClick={() => onSave()} style={{ width: 100, height: 40, borderRadius: 5, backgroundColor: "blue", border: "3px solid #ddd", color: "#fff", boxShadow: "-1px 2px 5px grey" }}>Save</button>
                                    :null
                                }
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                {
                                    inputform.purpose!='' || inputform.diagnosis!='' || inputform.remarks!='' ?
                                        <button onClick={() => clear()} style={{ width: 100, height: 40, borderRadius: 5, backgroundColor: "orange", border: "3px solid #ddd", color: "#fff", boxShadow: "-1px 2px 5px grey" }}>Clear</button>
                                    :null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateMedicalCertificate;
