import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';


import { ProgressSpinner } from 'primereact/progressspinner';
import PreSignUpListBtn from './component/dashboard/PreSignUpListBtn';
import Countdown from './extra/Countdown';
import { Skeleton } from 'primereact/skeleton';
import PhoneNumberSetup from './extra/PhoneNumberSetup';
import ChristmasBanner from './extra/ChristmasBanner';
import MedicalRecord from './component/MedicalRecord';
import PatientInfoPanel from './component/PatientInfoPanel';
import NewPatientForm from './component/NewPatientForm';
import EditPatientForm from './component/EditPatientForm';
import ConsultationForm from './component/ConsultationForm';
import AddVitalForm from './component/AddVitalForm';
import AppContext from './context/AppContext';
import Login from './Login';
import io from "socket.io-client";
import PatientRow from './extra/PatientRow';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Patient = ({ userInfo, newtransaction }) => {


    // const socket = io.connect(ENV.socketAPI);
            const menuRight = useRef(null);

    const position = sessionStorage.getItem('position');
    const { state, dispatch } = useContext(AppContext);
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed sidebar-collapse';


    const [newPatientForm, setNewPatientForm] = useState(false)
    const [editPersonalInfo, setEditPersonalInfo] = useState(false)
    const [startConsultionForm, setStartConsultionForm] = useState(false)
    const [addvitalForm, setAddVitalForm] = useState(false)


    const [inputform, setInputForm] = useState({})

    const [patientlist, setPatientList] = useState([])
    const [onclickedpatient, onClickedPatient] = useState(null)
    const [search, setSearch] = useState('')


    const [onclickedbutton, onClickedButton] = useState('PatientInfoPanel')
    const [resizepanel, setResizePanel] = useState('col-sm-4')


    const [innerWidth, setInnerWidth] = useState(0)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useMemo(async () => {

        var formData = new FormData();
        formData.append("patient_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setPatientList(res.data)
        })
    }, [ENV])

    const loadpatient = async () => {
        var formData = new FormData();
        formData.append("patient_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setPatientList(res.data)
        })
    }

    const searchData = (data, search, status) => {
        // let data = sales;
        if (search) {
            data = data?.filter((i) =>
                i?.fullname.toLowerCase().includes(search.trim().toLowerCase()) || i?.firstname.toLowerCase().includes(search.trim().toLowerCase()))
        }

        return data
    }



    const Logout = () => {
        sessionStorage.removeItem('username')
        localStorage.setItem('LabRequestCheckBox', JSON.stringify({}))
        window.location.assign('/')
    }

    // const [time, setTime] = useState('00:00:00')
    // setInterval(()=> {
    //     setTime(new Date().toLocaleTimeString())
    // }, 1000);


    const [trxlist, setTrxList] = useState([])

    useMemo(async () => {

        var formData = new FormData();
        formData.append("report_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setTrxList(res.data)
        })
    }, [ENV])
    useEffect(() => {

        window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
        return () => window.removeEventListener("resize", () => setWindowWidth(window.innerWidth));
    }, [])
    
    const consultaionlist = trxlist.filter((i) => {
        return i.delete == 0;
    });
    // alert(innerWidth)

    const Display = () => {
        if (windowWidth === 1440) {
            return (
                <>
                    <div style={{ paddingTop: 0, paddingBottom: 10, paddingLeft: 5, paddingRight: 5, display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", gap: 10 }}>
                        {
                            onclickedbutton == 'NewPatientForm' ?
                                <>
                                    <button onClick={() => onClickedButton('PatientInfoPanel')} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "red", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Cancel</button>
                                </>
                                :
                                <>
                                    <button onClick={() => { onClickedButton('NewPatientForm'); onClickedPatient(null) }} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>New</button>
                                </>
                        }
                        <div style={{ border: "1px solid #ddd", borderRadius: 5, display: "flex", flexDirection: "row", width: "100%" }}>
                            <input onChange={(e) => setSearch(e.target.value)} style={{ outline: "none", flexGrow: 1, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, height: 35, padding: 5, border: "none", }} placeholder='Type a patient name' />
                            <div style={{ width: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <span className='pi pi-search' ></span>
                            </div>
                        </div>
                    </div>
                    <div className='hidescroll' style={{ display: "flex", flexDirection: "column", gap: 2, overflowX: "auto", height: "90vh" }}>
                        {
                            searchData(patientlist, search, null).map((item, key) => {
                                return (
                                    <PatientRow key={key} onclickedpatient={onclickedpatient} item={item} onClickedPatient={onClickedPatient} onClickedButton={onClickedButton} />
                                )
                            })
                        }
                    </div>
                </>
            )
        } else if (windowWidth === 768) {
            return (
                <>
                    <div style={{ paddingTop: 0, paddingBottom: 10, paddingLeft: 5, paddingRight: 5, width: '100%', display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", gap: 10 }}>
                        {
                            onclickedbutton == 'NewPatientForm' ?
                                <>
                                    <button onClick={() => onClickedButton('PatientInfoPanel')} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: windowWidth === 1024 ? 30 : windowWidth === 768 ? 30 : 35, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null, backgroundColor: "red", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Cancel</button>
                                </>
                                :
                                <>
                                    <button onClick={() => { onClickedButton('NewPatientForm'); onClickedPatient(null) }} style={{ borderRadius: 5, border: "3px solid #fff", width: windowWidth === 768 ? "100%" : 120, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null, height: windowWidth === 1024 ? 30 : windowWidth === 768 ? 30 : 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>New</button>
                                </>
                        }
                        <div style={{ border: "1px solid #ddd", borderRadius: 5, display: "flex", flexDirection: "row", width: "100%" }}>
                            <input onChange={(e) => setSearch(e.target.value)} style={{ outline: "none", flexGrow: 1, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, height: 35, padding: 5, border: "none", }} placeholder='Type a patient name' />
                            <div style={{ width: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <span className='pi pi-search' ></span>
                            </div>
                        </div>
                    </div>
                    <div className='hidescroll' style={{ display: "flex", flexDirection: "column", gap: 2, overflowX: "auto", width: '100%', height: "90vh" }}>
                        {
                            searchData(patientlist, search, null).map((item, key) => {
                                return (
                                    <PatientRow key={key} onclickedpatient={onclickedpatient} item={item} onClickedPatient={onClickedPatient} onClickedButton={onClickedButton} />
                                )
                            })
                        }
                    </div>
                </>
            )
        } else if (windowWidth === 1024) {
            return (
                <>
                    <div style={{ paddingTop: 0, paddingBottom: 10, paddingLeft: 5, paddingRight: 5, width: '100%', display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", gap: 10 }}>
                        {
                            onclickedbutton == 'NewPatientForm' ?
                                <>
                                    <button onClick={() => onClickedButton('PatientInfoPanel')} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: windowWidth === 1024 ? 30 : 35, fontSize: windowWidth === 1024 ? 12 : null, backgroundColor: "red", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Cancel</button>
                                </>
                                :
                                <>
                                    <button onClick={() => { onClickedButton('NewPatientForm'); onClickedPatient(null) }} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, fontSize: windowWidth === 1024 ? 12 : null, height: windowWidth === 1024 ? 30 : 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>New</button>
                                </>
                        }
                        <div style={{ border: "1px solid #ddd", borderRadius: 5, display: "flex", flexDirection: "row", width: "100%" }}>
                            <input onChange={(e) => setSearch(e.target.value)} style={{ outline: "none", flexGrow: 1, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, height: 35, padding: 5, border: "none", }} placeholder='Type a patient name' />
                            <div style={{ width: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <span className='pi pi-search' ></span>
                            </div>
                        </div>
                    </div>
                    <div className='hidescroll' style={{ display: "flex", flexDirection: "column", gap: 2, overflowX: "auto", width: '100%', height: "90vh" }}>
                        {
                            searchData(patientlist, search, null).map((item, key) => {
                                return (
                                    <PatientRow key={key} onclickedpatient={onclickedpatient} item={item} onClickedPatient={onClickedPatient} onClickedButton={onClickedButton} />
                                )
                            })
                        }
                    </div>
                </>
            )
        } else if (windowWidth === 1080) {
            return (
                <>
                    <div style={{ paddingTop: 0, paddingBottom: 10, paddingLeft: 5, paddingRight: 5, width: '100%', display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", gap: 10 }}>
                        {
                            onclickedbutton == 'NewPatientForm' ?
                                <>
                                    <button onClick={() => onClickedButton('PatientInfoPanel')} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "red", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Cancel</button>
                                </>
                                :
                                <>
                                    <button onClick={() => { onClickedButton('NewPatientForm'); onClickedPatient(null) }} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>New</button>
                                </>
                        }
                        <div style={{ border: "1px solid #ddd", borderRadius: 5, display: "flex", flexDirection: "row", width: "100%" }}>
                            <input onChange={(e) => setSearch(e.target.value)} style={{ outline: "none", flexGrow: 1, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, height: 35, padding: 5, border: "none", }} placeholder='Type a patient name' />
                            <div style={{ width: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <span className='pi pi-search' ></span>
                            </div>
                        </div>
                    </div>
                    <div className='hidescroll' style={{ display: "flex", flexDirection: "column", gap: 2, overflowX: "auto", width: '100%', height: "90vh" }}>
                        {
                            searchData(patientlist, search, null).map((item, key) => {
                                return (
                                    <PatientRow key={key} onclickedpatient={onclickedpatient} item={item} onClickedPatient={onClickedPatient} onClickedButton={onClickedButton} />
                                )
                            })
                        }
                    </div>
                </>
            )
        } else if (windowWidth === 360) {

            const items = [
                {
                    label: '',
                    items: [
                        {
                            label: 'Add New Patient',
                            icon: 'pi pi-plus'
                        },
                        {
                            label: 'Start Consultation',
                            icon: 'pi pi-user-edit'
                        }
                    ]
                }
            ];

            return (
                <>
                    <div style={{ marginTop: 10, display: "flex", flexDirection: "column", gap: 10 }}>
                        <div style={{ width: "100%", height: 100, borderRadius: 15, padding: 5, border: "0px solid #ddd", boxShadow: "0px 1px 2px grey", backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", gap: 10 }}>
                            <div style={{ width: 80, height: 50, position: "relative" }}>
                                <img src={require('./component/pps-logo.png')} className="img-circle elevation-2" alt="User Image" style={{ width: "100%" }} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: 10, width: "100%" }}>
                                <div>
                                    <div style={{ fontSize: 20, fontWeight: "bolder" }}>Daniel Cachuela Aguilar, Jr</div>
                                    <div style={{ fontSize: 15 }}>MD, FPPS, GENERAL PEDIATRICS</div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", marginRight: 10 }}>
                                    <span onClick={() => Logout()} style={{ textDecoration: "underline", color: "red" }}>Logout</span>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10 }}>
                            <div style={{ border: "1px solid #009688", borderRadius: 6, display: "flex", flexDirection: "row", width: "100%" }}>
                                <div style={{ width: 40, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: "5px 0px 0px 5px", backgroundColor: " #fff" }}>
                                    <span className='pi pi-search' style={{fontSize: 20, color: "#a3a3a3"}} ></span>
                                </div>
                                <input onChange={(e) => setSearch(e.target.value)} style={{ outline: "none", width: "100%", flexGrow: 1, borderTopLeftRadius: 0, outline: "none", borderBottomLeftRadius: 0, height: "100%", padding: 5, border: "none", backgroundColor: " #fff" }} placeholder='Search a patient name' />
                                <button outlined={false} onClick={(event) => menuRight.current.toggle(event)} style={{ width: 50, height: 45, display: "flex", outline: "none", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "#009688", border: "1px solid #009688", borderRadius: "0px 5px 5px 0px" }}>
                                    <i className='pi pi-align-justify' style={{color: "#fff"}}/>
                                </button>
                                <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                            </div>
                        </div>
                        {/* <div style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, boxShadow: "0px 1px 2px grey" }}>
                            <div><span style={{ fontWeight: "bolder" }}>Total Patient</span>: {patientlist?.length}</div>
                        </div> */}
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 5 }}>
                            <div style={{ width: 150, height: 60, fontWeight: "bolder", flexGrow: 1, border: "0px solid #ddd", boxShadow: "0px 1px 2px grey", borderRadius: 5, backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 1}}>
                                    <span><span style={{ fontWeight: "bolder", fontSize:12 }}>Total Patient</span></span>
                                    <span>{patientlist?.length}</span>
                                </div>
                            </div>
                            <div onClick={() => window.location.assign('/report')} style={{ width: 150, height: 60, fontWeight: "bolder", flexGrow: 1, border: "0px solid #ddd", boxShadow: "0px 1px 2px grey", borderRadius: 5, backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                Report
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: 5, marginBottom: 55, marginTop: 10 }}>
                            <div>
                                Transactions
                            </div>
                            {
                                consultaionlist.length > 0 ?
                                    (
                                        [...consultaionlist].map((i, k) => {
                                            return (
                                                <div style={{ padding: 5, borderRadius: 5, backgroundColor: "#fff", boxShadow: "0px 1px 2px grey" }}>
                                                    <div><b>{i.fullname}</b></div>
                                                    <div>
                                                        {
                                                            i.method != 'Input Amount' ?
                                                                <b>{i.method}</b>
                                                                :
                                                                <>
                                                                    {
                                                                        i.status == 'paid' ?
                                                                            <>
                                                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                                                                    <span style={{ color: "green", fontWeight: 600 }}>Paid</span>
                                                                                    {/* <span style={{ color: "#000", fontWeight: 600 }}>{'P ' + i.amount}</span> */}
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                                                                <span style={{ color: "#c35e18", fontWeight: 600 }}>Pending Payment</span>
                                                                                <span style={{ color: "#000", fontWeight: 600 }}>{'P ' + i.amount}</span>
                                                                            </div>
                                                                    }
                                                                </>
                                                        }
                                                    </div>
                                                    <div>{i.date_time}</div>
                                                </div>
                                            )
                                        }).slice(0, 10)
                                    ) :
                                    <>
                                        <Skeleton width="100rem" height="4rem"></Skeleton>
                                        <Skeleton width="100rem" height="4rem"></Skeleton>
                                        <Skeleton width="100rem" height="4rem"></Skeleton>
                                        <Skeleton width="100rem" height="4rem"></Skeleton>
                                        <Skeleton width="100rem" height="4rem"></Skeleton>
                                    </>
                            }
                        </div>
                    </div>
                </>
            )
        } 
        else {
            return (
                <>
                    <div style={{ paddingTop: 0, paddingBottom: 10, paddingLeft: 5, paddingRight: 5, display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10 }}>
                        <div style={{ border: "1px solid #ddd", borderRadius: 5, display: "flex", flexDirection: "row", width: "90%" }}>
                            <input onChange={(e) => setSearch(e.target.value)} style={{ outline: "none", flexGrow: 1, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, height: 35, padding: 5, border: "none", }} placeholder='Type a patient name' />
                            <div style={{ width: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <span className='pi pi-search' ></span>
                            </div>
                        </div>
                        {
                            onclickedbutton == 'NewPatientForm' ?
                                <>
                                    <button onClick={() => onClickedButton('PatientInfoPanel')} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "red", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Cancel</button>
                                </>
                                :
                                <>
                                    <button onClick={() => { onClickedButton('NewPatientForm'); onClickedPatient(null) }} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>New</button>
                                </>
                        }
                    </div>
                    <div className='hidescroll' style={{ display: "flex", flexDirection: "column", gap: 2, overflowX: "auto", height: "90vh" }}>
                        {
                            searchData(patientlist, search, null).map((item, key) => {
                                return (
                                    <PatientRow key={key} onclickedpatient={onclickedpatient} item={item} onClickedPatient={onClickedPatient} onClickedButton={onClickedButton} />
                                )
                            })
                        }
                    </div>
                </>
            )
        }
    }

    // if (innerWidth) {
    //     alert(innerWidth)
    // }

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                windowWidth === 768 || windowWidth === 1024 || windowWidth === 1080 ?
                        <Header />
                    : null
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            {
                position != 'cashier' ?
                    <Navbar userInfo={userInfo} />
                    : null
            }
            {/* Content Wrapper. Contains page content */}
            <div className={position != 'cashier' ? "content-wrapper" : null}>
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}
                        {/* <Countdown second={15} /> */}
                        {/* Main row */}
                        <div className="row" style={{ display: "none" }}>
                            {/* Left col */}
                            {/* /.Left col */}
                            {/* right col (We are only adding the ID to make the widgets sortable)*/}
                            <div className='col-sm-12'>
                                <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                                    <button style={{ borderRadius: 5, width: 150, height: 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold", border: "3px solid #fff", boxShadow: "-1px 2px 5px grey" }}>New</button>
                                </div>
                            </div>
                            {/* right col */}
                        </div>
                        <div className="row" style={{ height: "90vh" }}>
                        {/* className={resizepanel == 'col-sm-3' ? 'col-sm-3' : 'col-sm-3'} */}
                            <div style={{ padding: 0, width: windowWidth === 1080 ? "30%" : windowWidth === 360 ? "100%" : windowWidth === 768 ? "30%" : windowWidth === 1920 ? "20%" : windowWidth === 1024 ? "30%" : null}}>
                                <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                    {
                                        Display()
                                    }
                                </div>
                            </div>
                            <div style={{ padding: 0, height: "90vh", width: windowWidth === 1920 ? "80%" : windowWidth === 1080 ? "70%" : windowWidth === 1024 ? "70%" : windowWidth === 768 ? "70%" : null }}>
                                {
                                    onclickedpatient ?
                                        <>
                                            {
                                                onclickedbutton == 'PatientInfoPanel' ?
                                                    <PatientInfoPanel setAddVitalForm={setAddVitalForm} onclickedpatient={onclickedpatient} onClickedPatient={onClickedPatient} onclickedbutton={onclickedbutton} onClickedButton={onClickedButton} setResizePanel={setResizePanel} resizepanel={resizepanel} loadpatient={loadpatient} />
                                                    // patientlist, setPatientList
                                                    : null
                                            }
                                            {
                                                onclickedbutton == 'EditPatientForm' ?
                                                    <EditPatientForm onclickedpatient={onclickedpatient} onClickedPatient={onClickedPatient} onclickedbutton={onclickedbutton} onClickedButton={onClickedButton} loadpatient={loadpatient} />
                                                    : null
                                            }
                                            {
                                                onclickedbutton == 'ConsultationForm' ?
                                                    <ConsultationForm onclickedpatient={onclickedpatient} onclickedbutton={onclickedbutton} onClickedButton={onClickedButton} />
                                                    : null
                                            }
                                            {
                                                onclickedbutton == 'AddVitalForm' ?
                                                    <AddVitalForm onclickedpatient={onclickedpatient} onclickedbutton={onclickedbutton} onClickedButton={onClickedButton} />
                                                    : null
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                onclickedbutton == 'NewPatientForm' ?
                                                    <NewPatientForm onClickedPatient={onClickedPatient} onclickedbutton={onclickedbutton} onClickedButton={onClickedButton} loadpatient={loadpatient} />
                                                    :
                                                    <div style={{ width: "100%", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                        <img src={require('./component/doctor-symbol-universal-png-2.png')} style={{ width: "50%", height: "50%" }} />
                                                        <span style={{ textShadow: "0 0 3px #000, 0 0 5px #000", fontSize: 30, color: "#fff" }}>No Selected Patient!</span>
                                                    </div>

                                            }
                                        </>
                                }
                            </div>
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {
                innerWidth === 360 ?
                    <div style={{ position: "fixed", height: 50, bottom: 0, width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", width: "100%" }}>
                            <div style={{ width: "50%", border: "1px solid #ddd", backgroundColor: "#fff", height: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>Home</div>
                            <div style={{ width: "50%", border: "1px solid #ddd", backgroundColor: "#fff", height: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>Account</div>
                        </div>
                    </div>
                    : null
            }
        </div>

    );
}

export default Patient;
