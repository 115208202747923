import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import ENV from '../ENV';
import swal from 'sweetalert';

const AddVitalForm = ({ onclickedpatient, onclickedbutton, onClickedButton }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [inputform, setInputForm] = useState({
        "firstname": "",
        "middlename": "",
        "lastname": "",
        "suffix": "",
        "birthday": "",
        "civil_status": "",
        "contact": "",
        "email_address": "",
        "weight": "",
        "height": "",
        "blood_type": "",
        "temperature": "",
        "systolic": "",
        "diastolic": "",
        "heart_rate": "",
        "respiration_rate": "",
        "allergies": "",
        "chronic_diseases": "",
        "occupation": "",
        "gender": ""
    })
    // console.log(inputform)

    const onSubmit = async () => {
        // alert(onclickedpatient.patient_id)
        if (inputform.weight != '' &&
            inputform.height != '' &&
            inputform.blood_type != '' &&
            inputform.temperature != '' &&
            inputform.systolic != '' &&
            inputform.diastolic != '' &&
            inputform.heart_rate != '' &&
            inputform.respiration_rate != '') {
            var formData = new FormData();
            formData.append("vital_add", 1);
            formData.append("patient_id", onclickedpatient.patient_id);
            formData.append("weight", inputform.weight);
            formData.append("height", inputform.height);
            formData.append("blood_type", inputform.blood_type);
            formData.append("temperature", inputform.temperature);
            formData.append("systolic", inputform.systolic);
            formData.append("diastolic", inputform.diastolic);
            formData.append("heart_rate", inputform.heart_rate);
            formData.append("respiration_rate", inputform.respiration_rate);
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'process.php',
                data: formData,
            }).then((res) => {
                swal({
                    title: "Done!",
                    text: "Successfull Save",
                    icon: "success",
                    timer: 1000,
                    button: false,
                }).then(() => {
                    // console.log(res.data);
                    onClickedButton('PatientInfoPanel')
                });
            })
        }
    }

    useEffect(() => {

        window.addEventListener("resize", () => setWindowWidth(window.innerWidth)); //const [windowWidth, setWindowWidth] = useState(window.innerWidth)
        return () => window.removeEventListener("resize", () => setWindowWidth(window.innerWidth));
    }, [])


    return (
        <div className='hidescroll' style={{ height: "100vh", overflow: "scroll" }}>
            <div style={{ padding: 10, borderBottom: "2px solid #fff", height: "100vh", backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                <div style={{ width: windowWidth === 768 ? "80%" : windowWidth === 1024 ? "70%" : "50%" }}>
                    <div style={{ fontSize: 20, fontWeight: "bolder" }}>Patient Vitals</div>
                </div>
                <div style={{ width: windowWidth === 768 ? "80%" : windowWidth === 1024 ? "70%" : "50%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div>
                        <div>Weight</div>
                        <div>
                            <input name='weight' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                    <div>
                        <div>Height</div>
                        <div>
                            <input name='height' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                </div>
                <div style={{ width: windowWidth === 768 ? "80%" : windowWidth === 1024 ? "70%" : "50%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div>
                        <div>Blood Type</div>
                        <div>
                            <input name='blood_type' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                    <div>
                        <div>Temperature</div>
                        <div>
                            <input name='temperature' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                </div>
                <div style={{ width: windowWidth === 768 ? "80%" : windowWidth === 1024 ? "70%" : "50%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div>
                        <div>Systolic</div>
                        <div>
                            <input name='systolic' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                    <div>
                        <div>Diastolic</div>
                        <div>
                            <input name='diastolic' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                </div>
                <div style={{ width: windowWidth === 768 ? "80%" : windowWidth === 1024 ? "70%" : "50%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div>
                        <div>Heart Rate</div>
                        <div>
                            <input name='heart_rate' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                    <div>
                        <div>Respiration Rate</div>
                        <div>
                            <input name='respiration_rate' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                </div>
                <div style={{ width: windowWidth === 768 ? "80%" : windowWidth === 1024 ? "70%" : "50%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    {/* onclickedbutton == 'PatientInfoPanel' */}
                    <button onClick={() => onClickedButton('PatientInfoPanel')} style={{ width: 200, height: windowWidth === 768 ? 35 : 45, borderRadius: 5, border: "3px solid #fff", backgroundColor: "red", color: "#fff", boxShadow: "-1px 2px 5px grey" }}>Cancel</button>
                    <button onClick={() => onSubmit()} style={{ width: 200, height: windowWidth === 768 ? 35 : 45, borderRadius: 5, border: "3px solid #fff", backgroundColor: "blue", color: "#fff", boxShadow: "-1px 2px 5px grey" }}>Submit</button>
                </div>

            </div>
        </div>
    );
}

export default AddVitalForm;
