import axios from 'axios';
import React, { useMemo, useState } from 'react';
import ENV from '../ENV';
import swal from 'sweetalert';

const NewPatientForm = ({ onClickedPatient, onclickedbutton, onClickedButton, loadpatient }) => {
    const [inputform, setInputForm] = useState({
        "firstname": "",
        "middlename": "",
        "lastname": "",
        "suffix": "",
        "birthday": "",
        "civil_status": "",
        "contact": "",
        "address": "",
        "email_address": "",
        "weight": "",
        "height": "",
        "blood_type": "",
        "temperature": "",
        "systolic": "",
        "diastolic": "",
        "heart_rate": "",
        "respiration_rate": "",
        "allergies": "",
        "chronic_diseases": "",
        "occupation": "",
        "gender": ""
    })
    // console.log(inputform)

    // useMemo(async () => {

    //     var formData = new FormData();
    //     formData.append("patient_add", 1);
    //     formData.append("firstname", inputform.firstname);
    //     formData.append("middlename", inputform.middlename);
    //     formData.append("lastname", inputform.lastname);
    //     formData.append("suffix", inputform.suffix);
    //     formData.append("birthday", inputform.birthday);
    //     formData.append("civil_status", inputform.civil_status);
    //     formData.append("contact", inputform.contact);
    //     formData.append("email_address", inputform.email_address);
    //     formData.append("weight", inputform.weight);
    //     formData.append("height", inputform.height);
    //     formData.append("blood_type", inputform.blood_type);
    //     formData.append("temperature", inputform.temperature);
    //     formData.append("systolic", inputform.systolic);
    //     formData.append("diastolic", inputform.diastolic);
    //     formData.append("heart_rate", inputform.heart_rate);
    //     formData.append("respiration_rate", inputform.respiration_rate);
    //     formData.append("allergies", inputform.allergies);
    //     formData.append("chronic_diseases", inputform.chronic_diseases);
    //     formData.append("occupation", inputform.occupation);
    //     formData.append("gender", inputform.gender);
    //     await axios({
    //         method: "post",
    //         url: ENV.DOMAIN + 'process.php',
    //         data: formData,
    //     }).then((res) => {

    //     })
    // }, [ENV])

    const onSubmit = async () => {

        var formData = new FormData();
        formData.append("patient_add", 1);
        formData.append("firstname", inputform.firstname);
        formData.append("middlename", inputform.middlename);
        formData.append("lastname", inputform.lastname);
        formData.append("suffix", inputform.suffix);
        formData.append("birthday", inputform.birthday);
        formData.append("civil_status", inputform.civil_status);
        formData.append("contact", inputform.contact);
        formData.append("address", inputform.address);
        formData.append("email_address", inputform.email_address);
        formData.append("weight", inputform.weight);
        formData.append("height", inputform.height);
        formData.append("blood_type", inputform.blood_type);
        formData.append("temperature", inputform.temperature);
        formData.append("systolic", inputform.systolic);
        formData.append("diastolic", inputform.diastolic);
        formData.append("heart_rate", inputform.heart_rate);
        formData.append("respiration_rate", inputform.respiration_rate);
        formData.append("allergies", inputform.allergies);
        formData.append("chronic_diseases", inputform.chronic_diseases);
        formData.append("occupation", inputform.occupation);
        formData.append("gender", inputform.gender);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            swal({
                title: "Done!",
                text: "Successfull Added",
                icon: "success",
                timer: 1000,
                button: false,
            }).then(() => {
                onClickedButton('PatientInfoPanel')
                // onClickedPatient(JSON.parse(res.data));
                loadpatient()
                // console.log(res.data);
            });
            // console.log(res.data);
        })
    }

    return (
        <div className='hidescroll' style={{overflow: "scroll", height: "95vh" }}>
            <div style={{ padding: 10, borderBottom: "2px solid #fff", backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 10 }}>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%" }}>
                    <div style={{ fontSize: 20, fontWeight: "bolder" }}>Personal Information</div>
                </div>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%" }}>
                    <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>First Name</div>
                    <div>
                        <input name='firstname' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                    </div>
                </div>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%" }}>
                    <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Middle Name <span style={{ color: "#a2a2a2" }}>(optional)</span></div>
                    <div>
                        <input name='middlename' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                    </div>
                </div>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%" }}>
                    <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Last Name</div>
                    <div>
                        <input name='lastname' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                    </div>
                </div>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%" }}>
                    <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Suffix <span style={{ color: "#a2a2a2" }}>(optional)</span></div>
                    <div>
                        <input name='suffix' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                    </div>
                </div>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%" }}>
                    <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Address</div>
                    <div>
                        <textarea name='address' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ padding: 5, width: "100%", height: 100, borderRadius: 5, resize: "none", border: "1px solid #ddd" }} />
                    </div>
                </div>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%" }}>
                    <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Gender</div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: 30, gap: 10 }}>
                            <label style={{ display: "flex", flexDirection: "row", alignItems: "center", gap:5, fontWeight:"normal"}}><input name='gender' value={"Male"} onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} type='radio' style={{ height: 20, width: 20 }} /> Male</label>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: 30, gap: 10 }}>
                            <label style={{ display: "flex", flexDirection: "row", alignItems: "center", gap:5, fontWeight:"normal"}}><input name='gender' value={"Female"} onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} type='radio' style={{ height: 20, width: 20 }} /> Female</label>
                        </div>
                    </div>
                </div>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10 }}>
                    <div style={{width: "100%"}}>
                        <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Birthdate</div>
                        <div style={{width: "100%"}}>
                            <input type='date' name='birthday' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                    <div style={{width: "100%"}}>
                        <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Civil Status</div>
                        <div style={{width: "100%"}}>
                            <select name='civil_status' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} value={inputform?.civil_status} style={{ height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }}>
                                <option value="">...</option>
                                <option value={"single"}>Single</option>
                                <option value={"married"}>Married</option>
                                <option value={"separated"}>Separated</option>
                                <option value={"widowed"}>Widowed</option>
                            </select>
                            {/* <input name='civil_status' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} /> */}
                        </div>
                    </div>
                </div>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%" }}>
                    <div style={{ fontSize: 20, fontWeight: "bolder" }}>Contact Information</div>
                </div>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%" }}>
                    <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Mobile Number</div>
                    <div>
                        <input name='contact' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                    </div>
                </div>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%" }}>
                    <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Email Address</div>
                    <div>
                        <input name='email_address' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                    </div>
                </div>
            </div>
            <div style={{ padding: 10, borderBottom: "2px solid #fff", backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 10 }}>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%" }}>
                    <div style={{ fontSize: 20, fontWeight: "bolder" }}>Patient Vitals</div>
                </div>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10 }}>
                    <div style={{width:"100%"}}>
                        <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Weight</div>
                        <div>
                            <input name='weight' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                    <div style={{width:"100%"}}>
                        <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Height</div>
                        <div>
                            <input name='height' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                </div>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10 }}>
                    <div style={{width:"100%"}}>
                        <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Blood Type</div>
                        <div>
                            <input name='blood_type' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                    <div style={{width:"100%"}}>
                        <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Temperature</div>
                        <div>
                            <input name='temperature' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                </div>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10 }}>
                    <div style={{width:"100%"}}>
                        <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Systolic</div>
                        <div>
                            <input name='systolic' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                    <div style={{width:"100%"}}>
                        <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Diastolic</div>
                        <div>
                            <input name='diastolic' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                </div>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10 }}>
                    <div style={{width:"100%"}}>
                        <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Heart Rate</div>
                        <div>
                            <input name='heart_rate' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                    <div style={{width:"100%"}}>
                        <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Respiration Rate</div>
                        <div>
                            <input name='respiration_rate' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                </div>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%" }}>
                    <div style={{ fontSize: 20, fontWeight: "bolder" }}>Other Information</div>
                </div>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%" }}>
                    <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Allergies</div>
                    <div>
                        <input name='allergies' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                    </div>
                </div>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%" }}>
                    <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Chronic Diseases</div>
                    <div>
                        <input name='chronic_diseases' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                    </div>
                </div>
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%" }}>
                    <div style={{fontSize: window.innerWidth === 768 ? 12 : window.innerWidth === 1024 ? 12 : null }}>Occupation</div>
                    <div>
                        <input name='occupation' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                    </div>
                </div>
                {/* <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%" }}>
                    <div style={{ fontSize: 20, fontWeight: "bolder" }}>if Pediatric, Geriatric, or PWD</div>
                    <div>Parent or Guardian's Name</div>
                    <div>
                        <input style={{ height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                    </div>
                </div> */}
                <div style={{ width: window.innerWidth === 768 ? "80%" : window.innerWidth === 1024 ? "80%" : "50%", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>

                    <button onClick={() => onSubmit()} style={{ width: 200, height: 45, borderRadius: 5, border: "3px solid #fff", backgroundColor: "blue", color: "#fff", fontWeight: 600, boxShadow: "-1px 2px 5px grey" }}>Submit</button>
                </div>

            </div>
        </div>
    );
}

export default NewPatientForm;
