import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import ENV from '../ENV';

const MedicationCertPDFViewer = ({ item, width = 150, view = false }) => {
    // console.log(item.prescription)
    const [open, setOpen] = useState(false);
    return (
        <>
            <Sidebar visible={open} header={<>Change Password</>} position="right" style={{ minHeight: 400, minWidth: 500, borderRadius: 0 }} onHide={() => setOpen(false)}>
                <iframe src={ENV.DOMAIN + "medical_cetificatePDF.php?trans_id=" + item.transaction_id} style={{ width: "100%", height: "100%" }} />
            </Sidebar>

            <div onClick={() => view == true ? null : setOpen(true)} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", cursor: view == true ? null : "pointer", padding: 10, width: width, backgroundColor: "#fff", boxShadow: "-1px 2px 5px grey", borderRadius: 5 }}>
                <span>Medical Certificate</span>
                {
                    view == true ?
                    <span onClick={() => setOpen(true)} style={{color: "green", cursor: "pointer"}}>View</span>
                    :null
                }
            </div>
        </>
    );
}

export default MedicationCertPDFViewer;
