import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import ENV from '../ENV';
import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import swal from 'sweetalert';
import PrescriptionPDFViewer from './PrescriptionPDFViewer';
import MedicationCertPDFViewer from './MedicationCertPDFViewer';
import LabRequestFormView from './LabRequestFormView';

const PaymentConsultDailog = (props) => {

    const [valueinput, setValueInput] = useState({ tendered: 0 })
    const [changevalue, setChangeValue] = useState(0)

    console.log(props.item)


    const proceedButton = async () => {
        // console.log(props.item)
        if (Number(props.item.amount) <= Number(valueinput.tendered).toFixed(2)) {
            console.log(Number(props.item.amount), Number(valueinput.tendered).toFixed(2))
        }

        var formData = new FormData();
        formData.append("complete_transaction", 1);
        formData.append("transaction_id", props.item.transaction_id);
        formData.append("patient_id", props.item.patient_id);
        formData.append("tendered", valueinput.tendered);
        formData.append("change", changevalue);
        formData.append("receiveby", sessionStorage.getItem('username'));
        console.log(JSON.stringify(formData))
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            if (res.data[0].status == 'success') {
                swal({
                    title: "Done!",
                    text: "Successfull Paid",
                    icon: "success",
                    timer: 1000,
                    button: false,
                }).then(() => {
                    props.loadData()
                    setValueInput({ tendered: 0 })
                    setChangeValue(0)
                    handleClose()
                });
            }
            props.loadData()
            setValueInput({ tendered: 0 })
            setChangeValue(0)
        })

    }

    const handleClose = () => {
        props.setVisible(false)
        setValueInput({ ...valueinput, "tendered": 0 })
    }


    return (
        <>
            <Dialog header={
                <span style={{ fontWeight: "bolder", fontSize: 20 }}>Payment Summary</span>
            }
                footer={
                    props.item.method == 'Input Amount' ?
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            {
                                Number(props.item.amount) == Number(valueinput.tendered).toFixed(2) ?
                                    <button onClick={() => proceedButton()} style={{ display: "flex", width: 200, justifyContent: "center", alignItems: "center", fontWeight: 600, borderRadius: 5, backgroundColor: "rgb(67, 190, 160)", color: "#fff", border: "3px solid #fff", boxShadow: "-1px 2px 5px grey", height: 40 }}>Complete Payment</button>
                                    : null
                            }
                        </div>
                        : null
                }
                draggable={false}
                visible={props.visible} style={{ width: '30vw' }} onHide={() => handleClose()}>
                <div>
                    <div>
                        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                            {
                                props.item?.prescription?.length > 0 ?
                                    <PrescriptionPDFViewer item={props.item} width={"100%"} view={true} />
                                    : null
                            }
                            {
                                props.item?.med_cert?.length > 0 ?
                                    <MedicationCertPDFViewer item={props.item} width={"100%"} view={true} />
                                    : null
                            }
                            {
                                Object.keys(props.item.lab_request).length>0 ?
                                    <LabRequestFormView item={props.item} width={"100%"} view={true} />
                                    : null
                            }
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: 10 }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <span>
                                <span style={{ fontSize: 18 }}>Amount:</span> <span style={{ fontWeight: "bolder", fontSize: 25 }}>{props.item.amount}</span></span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 5 }}>
                            <span>Tendered: </span>
                            <input type='number' name='tendered' onChange={(e) => setValueInput({ ...valueinput, [e.target.name]: e.target.value })} className='form-control' placeholder='0.00' style={{ width: 100, textAlign: "right" }} />
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default PaymentConsultDailog;
