import React, { useContext, useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';


import { ProgressSpinner } from 'primereact/progressspinner';
import PreSignUpListBtn from './component/dashboard/PreSignUpListBtn';
import Countdown from './extra/Countdown';
import { Skeleton } from 'primereact/skeleton';
import PhoneNumberSetup from './extra/PhoneNumberSetup';
import ChristmasBanner from './extra/ChristmasBanner';
import PrescriptionPDFViewer from './extra/PrescriptionPDFViewer';
import MedicationCertPDFViewer from './extra/MedicationCertPDFViewer';
import AppContext from './context/AppContext';
import Login from './Login';
import LabRequestFormView from './extra/LabRequestFormView';
import ReportsRow from './extra/ReportsRow';
import { format } from 'date-fns';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Statistics = ({ userInfo }) => {
    const { state, dispatch } = useContext(AppContext);
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [trxlist, setTrxList] = useState([])

    useMemo(async () => {

        var formData = new FormData();
        formData.append("report_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setTrxList(res.data)
        })
    }, [ENV])

    const loadData = async () => {
        var formData = new FormData();
        formData.append("report_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setTrxList(res.data)
        })
    }

    
    const consultaionlist = trxlist.filter((i) => {
        return i.delete == 0;
    });

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];

    var lastday = function(y,m){
        return  new Date(y, m, 0).getDate();
    }

    const current = format(new Date(), 'yyyy')+'-'+format(new Date(), 'MM')+'-'+format(new Date(), 'dd')
    const daytitle = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayname = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    // console.log(new Date())
    const getDayName = (dateStr, locale) => {
        let date = new Date(dateStr);
        return date.toLocaleDateString(locale, { weekday: 'long' });
    };
    return (
        <div className="wrapper">
            {/* Navbar */}
            <Header />
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}
                        {/* <Countdown second={15} /> */}
                        {/* Main row */}
                        <div className="row">
                            {/* Left col */}
                            {/* /.Left col */}
                            {/* right col (We are only adding the ID to make the widgets sortable)*/}
                            <div className='col-sm-12'>
                                <div>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 20, padding: "10px 10px 10px 10px", border: "1px solid #ddd"}}>
                                        {/* <div style={{fontWeight: "bolder", width: 150, height: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: 5, border: "1px solid #ddd"}}>Daily</div> */}
                                        <div style={{fontWeight: "bolder", width: 150, height: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: 5, border: "1px solid #ddd"}}>Weekly</div>
                                        <div style={{fontWeight: "bolder", width: 150, height: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: 5, border: "1px solid #ddd"}}>Monthly</div>
                                        <div style={{fontWeight: "bolder", width: 150, height: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: 5, border: "1px solid #ddd"}}>Yearly</div>
                                    </div>

                                    <div>
                                        {/* weekly */}
                                        {/* monthly */}
                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "center", flexWrap: "wrap", gap: 10, width: "100%"}}>
                                            {
                                                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => {

                                                    // const lastWeekDate = new Date(('2024-'+i+'-01').getTime() - 7 * 24 * 60 * 60 * 1000);
                                                    // console.log(i, ' => ',42-lastday(format(new Date(), 'yyyy'), i))
                                                    // console.log(i % 7 == 0 ? i : 0)
                                                    let daysNumber =  [];
                                                    // console.log(daysNumber)
                                                    return (
                                                        <div style={{ width: "30%", borderRadius: 5, border: "1px solid #ddd" }}>
                                                            <div style={{ padding: 5, backgroundColor: "#ddd" }}>
                                                                <span style={{ fontWeight: "bolder" }}>{monthNames[i - 1]} {lastday(2024, i)}</span>
                                                            </div>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                                                {
                                                                    daytitle.map((i) => {
                                                                        return (
                                                                            <div style={{ width: "14.28571428571429%", border: "1px solid #fff", backgroundColor: "#c79807", padding: 5, textAlign: "center" }}>{i}</div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                                                {/* {                                                                                    
                                                                    Array(lastday(format(new Date(), 'yyyy'), i)).fill().map((d, k) => {
                                                                        var dateString = format(new Date(), 'yyyy')+'-'+String(i).padStart(2,0)+'-'+String(k + 1).padStart(2,0)
                                                                        // console.log(i+" => "+k + 1, getDayName(dateString, "en-GB"))
                                                                        daysNumber.push({"number": k + 1, "dayname": getDayName(dateString, "en-GB")})
                                                                    })
                                                                } */}
                                                                {
                                                                    Array(7).fill().map((d, x) => {
                                                                        return (
                                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                                {
                                                                                    Array(7).fill().map((dx, y) => {
                                                                                        return (
                                                                                            <div style={{ width: "14.28571428571429%", height: 70, border: "1px solid #ddd", padding: 5 }}>
                                                                                                {x} {y}
                                                                                            </div>
                                                                                        ) 
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        ) 
                                                                    })
                                                                }
                                                                {/* {
                                                                    Array(lastday(format(new Date(), 'yyyy'), i)).fill().map((d, k) => {
                                                                        var dateString = format(new Date(), 'yyyy')+'-'+String(i).padStart(2,0)+'-'+String(k + 1).padStart(2,0)
                                                                        return (
                                                                            <div style={{ width: "14.28571428571429%", height: 75, border: "1px solid #ddd", padding: 5 }}>
                                                                                {k + 1}
                                                                                <div>
                                                                                    { getDayName(dateString, "en-GB") }
                                                                                </div>display: "flex", flexDirection: "column"
                                                                            </div>
                                                                        ) 
                                                                    })
                                                                } */}
                                                                
                                                                {/* {                                                                    
                                                                    Array(lastday(format(new Date(), 'yyyy'), i)).fill().map((d, k) => {
                                                                        var dateString = format(new Date(), 'yyyy')+'-'+String(i).padStart(2,0)+'-'+String(k + 1).padStart(2,0)
                                                                        return (
                                                                            <div style={{ width: "14.28571428571429%", height: 75, border: "1px solid #ddd", padding: 5 }}>
                                                                                {k + 1}
                                                                                <div>
                                                                                    { getDayName(dateString, "en-GB") }
                                                                                </div>display: "flex", flexDirection: "column"
                                                                            </div>
                                                                        ) 
                                                                    })
                                                                } */}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }


                                        </div>
                                        {/* yearly */}
                                    </div>



                                </div>

                                {/* //////////////////////////////////////////////////// */}

                                {/* //////////////////////////////////////////////////// */}

                                {/* //////////////////////////////////////////////////// */}

                                <div style={{display: "none"}}>
                                    <div style={{ backgroundColor: "#fff", minHeight: "100vh" }}>
                                        <table className='table table-bordered'>
                                            <thead style={{backgroundColor:"#ddd"}}>
                                                <tr>
                                                    <th style={{maxWidth: 60}}>No.</th>
                                                    <th style={{width: 150}}>Date</th>
                                                    <th>Name</th>
                                                    <th>Medical Records</th>
                                                    <th>Payment Details</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    consultaionlist.map((i, k) => {
                                                        // console.log(i.delete_doc.lab_request_deleted, i.delete_doc.med_cert_deleted, i.delete_doc.pres_deleted, 'asdasd')

                                                        
                                                        return (
                                                            <ReportsRow key={k} k={k} i={i} loadData={loadData} />
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        {/* {
                                            trxlist.map((i, k) => {
                                                return (
                                                    <div style={{ borderRadius: 5, backgroundColor: "#fff", boxShadow: "-1px 2px 5px grey" }}>
                                                        <div style={{ padding: 10 }}>
                                                            <div style={{ paddingTop: 5, paddingBottom: 5, fontWeight: 600, fontSize: 20 }}>{i.fullname}</div>
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: 'center', gap: 10 }}>
                                                                {
                                                                    i.prescription.length > 0 ?
                                                                        <PrescriptionPDFViewer item={i} />
                                                                        : null
                                                                }
                                                                {
                                                                    i.med_cert != null ?
                                                                        <MedicationCertPDFViewer item={i} />
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", padding: 10, backgroundColor: "#ddd", borderBottomLeftRadius: 5, borderBottomRightRadius: 5, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                            <span>Transaction No: {i.transaction_id}</span>
                                                            <span>{i.date_time}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        } */}
                                    </div>
                                </div>
                            </div>
                            {/* right col */}
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
        </div>

    );
}

export default Statistics;
