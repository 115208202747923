import React, { useContext, useEffect, useReducer, useState } from 'react';
import CreateMedicalCertificate from './CreateMedicalCertificate';
import CreatePrescription from './CreatePrescription';
import ConsultationBillingForm from './ConsultationBillingForm';
import ChartingForm from './ChartingForm';
import ConsultaionOption from './ConsultaionOption';
import { useStateValue } from '../StateProvider';
import { initialState, reducer } from '../reducer';
import AppContext from '../context/AppContext';
import LabReqForm from './LabReqForm';

const ConsultationForm = ({ onclickedpatient, onclickedbutton, onClickedButton }) => {
    // sessionStorage.setItem('medCert', null)
    // const [state, dispatch] = useStateValue();

    const { state, dispatch } = useContext(AppContext);


    const [prescriptionform, PrescriptionForm] = useState('')
    const [consultaionform, onConsultationButton] = useState('')
    // console.log(JSON.parse(sessionStorage.getItem('medCert')))

    // const [medCertData, setMedCertData] = useState(JSON.parse(sessionStorage.getItem('medCert')) ?? [])
    // const index = medCertData.findIndex(i => i.patient_id === onclickedpatient.patient_id);

    // const [prescriptionlist, setPrescriptionList] = useState(JSON.parse(sessionStorage.getItem('PrescriptionList')) ?? [])
    // const prescriptiondata = prescriptionlist.filter((i) => {
    //     return i.patient_id === onclickedpatient.patient_id;
    // });
    // console.log(medCertData[index])
    // console.log(prescriptiondata)


    // JSON.parse(sessionStorage.getItem('PrescriptionList'))
    // JSON.parse(sessionStorage.getItem('medCert'))


    // localStorage.setItem('medCert', JSON.stringify([]))


    const clearbtn = () => {
        localStorage.removeItem('LabRequestCheckBox')
        onConsultationButton('')
    }

        {/* className='hidescroll' overflow: "scroll" */}
    return (
        <div style={{ height: "95vh" }}>
            {
                // window.innerWidth === 1080 ?
                    <div style={{ padding: 10, borderBottom: "2px solid #ddd", backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5, width: "100%" }}>
                            <div style={{ width: window.innerWidth === 1080 ? "40%" : window.innerWidth === 1920 ? "30%" : window.innerWidth === 1024 ? "40%" : null }}>
                                <div style={{ fontSize: window.innerWidth === 1024 ? 12 : window.innerWidth === 768 ? 12 : 18, fontWeight: 600 }}>{onclickedpatient.fullname}</div>
                                <div style={{ fontSize: window.innerWidth === 1024 ? 10 : window.innerWidth === 768 ? 10 : 15, display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center", gap: 10 }}>
                                    <span>{onclickedpatient.gender === 'male' ? 'Male' : 'Female'}</span>
                                    <span style={{ width: 5, height: 5, backgroundColor: "#ddd", borderRadius: 100 }}></span>
                                    <span>{onclickedpatient.age} years old</span>
                                </div>
                            </div>
                            <div style={{ width: window.innerWidth === 1080 ? "40%" : window.innerWidth === 1920 ? "30%" : window.innerWidth === 1024 ? "40%" : null, fontSize: window.innerWidth === 1024 ? 12 : window.innerWidth === 768 ? 12 : null }}>
                                <div>{onclickedpatient.contact}</div>
                                <div>{onclickedpatient.email_address}</div>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                            <button onClick={() => onClickedButton('PatientInfoPanel')} style={{ borderRadius: 5, border: "3px solid #fff", boxShadow: "-1px 2px 5px grey", width: 120, height: window.innerWidth === 1024 ? 30 : window.innerWidth === 768  ? 30 : 35, fontSize: window.innerWidth === 1024 ? 12 : window.innerWidth === 768 ? 12 : null, backgroundColor: "red", color: "#fff", fontWeight: "bold" }}>Cancel</button>
                        </div>
                    </div>
                    // :
                    // <div style={{ padding: 10, borderBottom: "2px solid #ddd", backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    //     <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5 }}>
                    //         <div style={{ width: 250 }}>
                    //             <div style={{ fontSize: 18, fontWeight: 600 }}>{onclickedpatient.fullname}</div>
                    //             <div style={{ fontSize: 15, display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center", gap: 10 }}>
                    //                 <span>{onclickedpatient.gender === 'male' ? 'Male' : 'Female'}</span>
                    //                 <span style={{ width: 5, height: 5, backgroundColor: "#ddd", borderRadius: 100 }}></span>
                    //                 <span>{onclickedpatient.age} years old</span>
                    //             </div>
                    //         </div>
                    //         <div style={{ width: 200 }}>
                    //             <div>{onclickedpatient.contact}</div>
                    //             <div>{onclickedpatient.email_address}</div>
                    //         </div>
                    //     </div>
                    //     <div>
                    //         <button onClick={() => onClickedButton('PatientInfoPanel')} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "red", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Cancel</button>
                    //     </div>
                    // </div>
            }
            {
                consultaionform === '' ?
                    <>
                        <ConsultaionOption onclickedpatient={onclickedpatient} onConsultationButton={onConsultationButton} />

                    </>
                    : null
            }
            {
                consultaionform === 'Charting' ?
                    <ChartingForm onclickedpatient={onclickedpatient} onConsultationButton={onConsultationButton} />
                    : null
            }
            {
                consultaionform === 'patient_billing' ?
                    <ConsultationBillingForm onclickedpatient={onclickedpatient} onConsultationButton={onConsultationButton} />
                    : null
            }
            {
                consultaionform === 'New Prescription' ?
                    <CreatePrescription onclickedpatient={onclickedpatient} onConsultationButton={onConsultationButton} />
                    // <>
                    // <div style={{padding:5, backgroundColor:"#fff"}}>
                    //     <div style={{padding:5}}>
                    //         <span style={{fontSize:25, fontWeight:600}}>Create Prescription</span>
                    //     </div>

                    // </div>
                    // </>
                    : null
            }
            {
                consultaionform === 'New Medical Certificate' ?
                    <CreateMedicalCertificate onclickedpatient={onclickedpatient} onConsultationButton={onConsultationButton} />
                    : null
            }
            {
                consultaionform === 'Lab Request Form' ?
                    <LabReqForm onclickedpatient={onclickedpatient} onConsultationButton={onConsultationButton} clearbtn={clearbtn} />
                    : null
            }
        </div>
    );
}

export default ConsultationForm;
