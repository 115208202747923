import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ENV from '../ENV';

const LabReqForm = ({ onclickedpatient, onConsultationButton, clearbtn }) => {
    // localStorage.removeItem('LabRequestCheckBox')
    if (localStorage.getItem('LabRequestCheckBox')) {
        var LabRequestCheckBox = JSON.parse(localStorage.getItem('LabRequestCheckBox'));
        // console.log(1)
    } else {
        var LabRequestCheckBox = {};
        // console.log(2)
    }

    const [ischecked, setIsChecked] = useState(LabRequestCheckBox)
    const [othersInput, setOthersInput] = useState({ serum_others: "", xray_others: "", ultrasound_others: "", ctscan_others: "", mri_others: "" })
    // {
    //     "cbc": false,
    //     "platelet": false,
    //     "blood_type": false,
    //     "serum": false,
    //     "serum_creatinine": false,
    //     "serum_bun": false,
    //     "serum_uric_acid": false,
    //     "serum_alt": false,
    //     "serum_ast": false,
    //     "serum_na_plus": false,
    //     "serum_k_plus": false,
    //     "serum_ca_plus2": false,
    //     "serum_ica_plus2": false,
    //     "serum_phos": false,
    //     "serum_mg_plus2": false,
    //     "serum_lipitprofile": false,
    //     "serum_fbs": false,
    //     "serum_rbs": false,
    //     "serum_hba1c": false,
    //     "serum_protime": false,
    //     "serum_aptt": false,
    //     "serum_albumin": false,
    //     "serum_troponin_i": false,
    //     "serum_abg": false,
    //     "serum_bilirubin": false,
    //     "serum_alkaline_phosphatase": false,
    //     "serum_bilirubin_total": false,
    //     "serum_bilirubin_direct": false,
    //     "serum_bilirubin_indirect": false,
    //     "serum_lipase": false,
    //     "serum_amylase": false,
    //     "serum_hbsAg": false,
    //     "serum_anti_hav": false,
    //     "serum_anti_hcv": false,
    //     "serum_anti_hbs_titer_quantitative": false,
    //     "serum_hiv": false,
    //     "serum_tsh": false,
    //     "serum_ft3": false,
    //     "serum_ft4": false,
    //     "serum_anti_tpo_antibodies": false,
    //     "serum_esr": false,
    //     "serum_crp": false,
    //     "serum_procalcitonin": false,
    //     "serum_ferritin": false,
    //     "serum_ldh": false,
    //     "serum_ana_quantitative": false,
    //     "serum_dsdna": false,
    //     "serum_c3_quantitative": false,
    //     "serum_aso_titer": false,
    //     "xray": false,
    //     "xray_chest": false,
    //     "xray_abdomen": false,
    //     "xray_spine": false,
    //     "xray_foot": false,
    //     "xray_foot_apo": false,
    //     "xray_chest_pa": false,
    //     "xray_chest_ap": false,
    //     "xray_chest_lateral": false,
    //     "xray_abdomen_supine": false,
    //     "xray_abdomen_upright": false,
    //     "xray_spine_cervical": false,
    //     "xray_spine_cervico_thoracic": false,
    //     "xray_spine_thoracic": false,
    //     "xray_spine_thoracolumbar": false,
    //     "xray_spine_lumbar": false,
    //     "ultrasound": false,
    //     "ultrasound_abdomen": false,
    //     "ultrasound_kub": false,
    //     "ultrasound_kubp": false,
    //     "ultrasound_abdomen_whole": false,
    //     "ultrasound_abdomen_upper": false,
    //     "ultrasound_abdomen_lower": false,
    //     "ultrasound_hepatobiliary": false,
    //     "ultrasound_thyroid": false,
    //     "ultrasound_breast": false,
    //     "ultrasound_hemithorax_with_mapping": false,
    //     "ultrasound_bilateral": false,
    //     "ultrasound_left": false,
    //     "ultrasound_right": false,
    //     "ultrasound_testicular": false,
    //     "ctscan": false,
    //     "ctscan_cranial": false,
    //     "ctscan_cranial_plain": false,
    //     "ctscan_cranial_contrast": false,
    //     "ctscan_cranial_angiography": false,
    //     "ctscan_chest": false,
    //     "ctscan_chest_plain": false,
    //     "ctscan_chest_contrast": false,
    //     "ctscan_abdomen": false,
    //     "ctscan_plain": false,
    //     "ctscan_triphasic_contrast": false,
    //     "ctscan_triple_contrast": false,
    //     "MRI": false,
    //     "MRI_Cranial": false,
    //     "MRI_spinal": false,
    //     "MRI_abdominal": false,
    //     "twelve_leads_ecg": false,
    //     "twoD_echo": false,
    //     "cardiac_stress_test": false,
    //     "carotid_doppler_scan": false,
    //     "carotid_doppler_with_vertebral_scan": false,
    //     "carotid_doppler_without_vertebral_scan": false,
    //     "arterial_duplex_scan": false,
    //     "arterial_duplex_scan_bilateral": false,
    //     "arterial_duplex_scan_left": false,
    //     "arterial_duplex_scan_right": false,
    //     "arterial_duplex_scan_upper_extremity": false,
    //     "arterial_duplex_scan_lower_extremity": false,
    //     "venous_duplex_scan": false,
    //     "venous_duplex_scan_lower_extremity": false,
    //     "venous_duplex_scan_upper_extremity": false,
    //     "venous_duplex_scan_right": false,
    //     "venous_duplex_scan_bilateral": false,
    //     "venous_duplex_scan_left": false
    // }

    // console.log(othersInput)

    useEffect(() => {
        if (ischecked?.serum == false) {

            ischecked.serum_creatinine = false;
            ischecked.serum_bun = false;
            ischecked.serum_uric_acid = false;
            ischecked.serum_alt = false;
            ischecked.serum_ast = false;
            ischecked.serum_na_plus = false;
            ischecked.serum_k_plus = false;
            ischecked.serum_ca_plus2 = false;
            ischecked.serum_ica_plus2 = false;
            ischecked.serum_phos = false;
            ischecked.serum_mg_plus2 = false;
            ischecked.serum_lipitprofile = false;
            ischecked.serum_fbs = false;
            ischecked.serum_rbs = false;
            ischecked.serum_hba1c = false;
            ischecked.serum_protime = false;
            ischecked.serum_aptt = false;
            ischecked.serum_albumin = false;
            ischecked.serum_troponin_i = false;
            ischecked.serum_abg = false;
            ischecked.serum_bilirubin = false;
            ischecked.serum_alkaline_phosphatase = false;
            ischecked.serum_bilirubin_total = false;
            ischecked.serum_bilirubin_direct = false;
            ischecked.serum_bilirubin_indirect = false;
            ischecked.serum_lipase = false;
            ischecked.serum_amylase = false;
            ischecked.serum_hbsAg = false;
            ischecked.serum_anti_hav = false;
            ischecked.serum_anti_hcv = false;
            ischecked.serum_anti_hbs_titer_quantitative = false;
            ischecked.serum_hiv = false;
            ischecked.serum_tsh = false;
            ischecked.serum_ft3 = false;
            ischecked.serum_ft4 = false;
            ischecked.serum_anti_tpo_antibodies = false;
            ischecked.serum_crp = false;
            ischecked.serum_esr = false;
            ischecked.serum_procalcitonin = false;
            ischecked.serum_ferritin = false;
            ischecked.serum_ldh = false;
            ischecked.serum_dsdna = false;
            ischecked.serum_ana_quantitative = false;
            ischecked.serum_c3_quantitative = false;
            ischecked.serum_aso_titer = false;
            setIsChecked(ischecked)
        }
        if (ischecked?.biomakers == false) {
            ischecked.biomakers_breast = false;
            ischecked.biomakers_breast_brca_1 = false;
            ischecked.biomakers_breast_brca_2 = false;
            ischecked.biomakers_breast_ca_15_3 = false;
            ischecked.biomakers_breast_ca_27_29 = false;
            ischecked.biomakers_breast_cea = false;
            ischecked.biomakers_breast_cea_125 = false;
            ischecked.biomakers_breast_her2_neu = false;
            ischecked.biomakers_ovarian = false;
            ischecked.biomakers_ovarian_afp = false;
            ischecked.biomakers_ovarian_ca_125 = false;
            ischecked.biomakers_thyroid = false;
            ischecked.biomakers_thyroid_thyroglobulin = false;
            ischecked.biomakers_pancreatic = false;
            ischecked.biomakers_pancreatic_ca_19_9 = false;
            ischecked.biomakers_liver = false;
            ischecked.biomakers_liver_afp = false;
            ischecked.biomakers_colorectal = false;
            ischecked.biomakers_colorectal_cea = false;
            ischecked.biomakers_colorectal_ca_19_9 = false;
            ischecked.biomakers_colorectal_fobt = false;
            ischecked.biomakers_testicular = false;
            ischecked.biomakers_testicular_hcg = false;
            ischecked.biomakers_testicular_afp = false;
            ischecked.biomakers_prostate = false;
            ischecked.biomakers_prostate_psa = false;
            ischecked.biomakers_germ_cell = false;
            ischecked.biomakers_germ_cell_hcg = false;
            ischecked.biomakers_germ_cell_afp = false;
            ischecked.biomakers_germ_cell_ldh = false;
            ischecked.biomakers_cervical = false;
            ischecked.biomakers_cervical_pap_smea = false;
        }
        if (ischecked?.biomakers_breast == false) {
            ischecked.biomakers_breast = false;
            ischecked.biomakers_breast_brca_1 = false;
            ischecked.biomakers_breast_brca_2 = false;
            ischecked.biomakers_breast_ca_15_3 = false;
            ischecked.biomakers_breast_ca_27_29 = false;
            ischecked.biomakers_breast_cea = false;
            ischecked.biomakers_breast_cea_125 = false;
            ischecked.biomakers_breast_her2_neu = false;
        }
        if (ischecked?.biomakers_ovarian == false) {
            ischecked.biomakers_ovarian_afp = false;
            ischecked.biomakers_ovarian_ca_125 = false;
        }
        if (ischecked?.biomakers_ovarian == false) {
            ischecked.biomakers_ovarian_afp = false;
            ischecked.biomakers_ovarian_ca_125 = false;
        }
        if (ischecked?.biomakers_thyroid == false) {
            ischecked.biomakers_thyroid_thyroglobulin = false;
        }
        if (ischecked?.biomakers_pancreatic == false) {
            ischecked.biomakers_pancreatic_ca_19_9 = false;
        }
        if (ischecked?.biomakers_liver == false) {
            ischecked.biomakers_liver_afp = false;
        }
        if (ischecked?.biomakers_colorectal == false) {
            ischecked.biomakers_colorectal_cea = false;
            ischecked.biomakers_colorectal_ca_19_9 = false;
            ischecked.biomakers_colorectal_fobt = false;
        }
        if (ischecked?.biomakers_testicular == false) {
            ischecked.biomakers_testicular_hcg = false;
            ischecked.biomakers_testicular_afp = false;
        }
        if (ischecked?.biomakers_prostate == false) {
            ischecked.biomakers_prostate_psa = false;
        }
        if (ischecked?.biomakers_germ_cell == false) {
            ischecked.biomakers_germ_cell_hcg = false;
            ischecked.biomakers_germ_cell_afp = false;
            ischecked.biomakers_germ_cell_ldh = false;
        }
        if (ischecked?.biomakers_cervical == false) {
            ischecked.biomakers_cervical_pap_smea = false;
        }
        if (ischecked?.xray == false) {

            ischecked.xray_chest = false;
            ischecked.xray_chest_pa = false;
            ischecked.xray_chest_ap = false;
            ischecked.xray_chest_lateral = false;
            ischecked.xray_chest_apicolordotic = false;
            ischecked.xray_abdomen = false;
            ischecked.xray_abdomen_supine = false;
            ischecked.xray_abdomen_upright = false;
            ischecked.xray_spine = false;
            ischecked.xray_spine_cervical = false;
            ischecked.xray_spine_cervico_thoracic = false;
            ischecked.xray_spine_thoracic = false;
            ischecked.xray_spine_thoracolumbar = false;
            ischecked.xray_spine_lumbar = false;
            ischecked.xray_foot = false;
            ischecked.xray_foot_apo = false;
            setIsChecked(ischecked)
        }
        if (ischecked?.xray_chest == false) {
            ischecked.xray_chest_pa = false;
            ischecked.xray_chest_ap = false;
            ischecked.xray_chest_lateral = false;
            ischecked.xray_chest_apicolordotic = false;
            setIsChecked(ischecked)
        }
        if (ischecked?.xray_abdomen == false) {
            ischecked.xray_abdomen_supine = false;
            ischecked.xray_abdomen_upright = false;
            setIsChecked(ischecked)
        }
        if (ischecked?.xray_spine == false) {
            ischecked.xray_spine_cervical = false;
            ischecked.xray_spine_cervico_thoracic = false;
            ischecked.xray_spine_thoracic = false;
            ischecked.xray_spine_thoracolumbar = false;
            ischecked.xray_spine_lumbar = false;
            setIsChecked(ischecked)
        }
        if (ischecked?.xray_foot == false) {
            ischecked.xray_foot_apo = false;
            setIsChecked(ischecked)
        }


        if (ischecked?.ultrasound == false) {
            ischecked.ultrasound = false;
            ischecked.ultrasound_abdomen = false;
            ischecked.ultrasound_kub = false;
            ischecked.ultrasound_kubp = false;
            ischecked.ultrasound_abdomen_whole = false;
            ischecked.ultrasound_abdomen_upper = false;
            ischecked.ultrasound_abdomen_lower = false;
            ischecked.ultrasound_hepatobiliary = false;
            ischecked.ultrasound_thyroid = false;
            ischecked.ultrasound_breast = false;
            ischecked.ultrasound_hemithorax_with_mapping = false;
            ischecked.ultrasound_bilateral = false;
            ischecked.ultrasound_left = false;
            ischecked.ultrasound_right = false;
            ischecked.ultrasound_testicular = false;
            setIsChecked(ischecked)
        }
        if (ischecked?.ultrasound_abdomen == false) {
            ischecked.ultrasound_abdomen_whole = false;
            ischecked.ultrasound_abdomen_upper = false;
            ischecked.ultrasound_abdomen_lower = false;
            setIsChecked(ischecked)
        }

        if (ischecked?.ctscan == false) {
            ischecked.ctscan = false;
            ischecked.ctscan_cranial = false;
            ischecked.ctscan_chest = false;
            ischecked.ctscan_abdomen = false;
            ischecked.ctscan_plain = false;
            ischecked.ctscan_triphasic_contrast = false;
            ischecked.ctscan_triple_contrast = false;
            ischecked.ctscan_chest_plain = false;
            ischecked.ctscan_chest_contrast = false;
            setIsChecked(ischecked)
        }
        if (ischecked?.ctscan_cranial == false) {
            ischecked.ctscan_cranial_plain = false;
            ischecked.ctscan_cranial_contrast = false;
            ischecked.ctscan_cranial_angiography = false;
            setIsChecked(ischecked)
        }
        if (ischecked?.ctscan_chest == false) {
            ischecked.ctscan_chest_plain = false;
            ischecked.ctscan_chest_contrast = false;
            setIsChecked(ischecked)
        }

        if (ischecked?.MRI == false) {
            ischecked.MRI_Cranial = false;
            ischecked.MRI_spinal = false;
            ischecked.MRI_abdominal = false;
            setIsChecked(ischecked)
        }

        if (ischecked?.carotid_doppler_scan == false) {
            ischecked.carotid_doppler_with_vertebral_scan = false;
            ischecked.carotid_doppler_without_vertebral_scan = false;
            setIsChecked(ischecked)
        }

        if (ischecked?.arterial_duplex_scan == false) {
            ischecked.arterial_duplex_scan_lower_extremity = false;
            ischecked.arterial_duplex_scan_upper_extremity = false;
            ischecked.arterial_duplex_scan_right = false;
            ischecked.arterial_duplex_scan_left = false;
            ischecked.arterial_duplex_scan_bilateral = false;
            setIsChecked(ischecked)
        }

        if (ischecked?.venous_duplex_scan == false) {
            ischecked.venous_duplex_scan_bilateral = false;
            ischecked.venous_duplex_scan_left = false;
            ischecked.venous_duplex_scan_right = false;
            ischecked.venous_duplex_scan_upper_extremity = false;
            ischecked.venous_duplex_scan_lower_extremity = false;
            setIsChecked(ischecked)
        }

        if (ischecked?.stool_exam == false) {
            ischecked.conc_method = false;
            ischecked.fobt = false;
            setIsChecked(ischecked)
        }

        // ischecked.serum_others = othersInput.serum_others;
        // ischecked.xray_others = othersInput.xray_others;
        // ischecked.ultrasound_others = othersInput.ultrasound_others;
        // ischecked.ctscan_others = othersInput.ctscan_others
        // ischecked.mri_others = othersInput.mri_others;
        // setIsChecked(ischecked)
        // else if(othersInput.xray_others == 'xray_others') {
        //     ischecked.xray_others = e.target.value;
        //     setOthersInput({xray_others: e.target.value})
        //     setIsChecked(ischecked)
        // } else if(othersInput.ultrasound_others == 'ultrasound_others') {
        //     ischecked.ultrasound_others = e.target.value;
        //     setOthersInput({ultrasound_others: e.target.value})
        //     setIsChecked(ischecked)
        // } else if(othersInput.ctscan_others == 'ctscan_others') {
        //     ischecked.ctscan_others = e.target.value;
        //     setOthersInput({ctscan_others: e.target.value})
        //     setIsChecked(ischecked)
        // } else if(othersInput.mri_others == 'mri_others') {
        //     ischecked.mri_others = e.target.value;
        //     setOthersInput({mri_others: e.target.value})
        //     setIsChecked(ischecked)
        // }

    }, [ischecked, othersInput])

    const insertValue = (e) => {
        // if(othersInput.serum_others == 'serum_others') {
        //     ischecked.serum_others = e.target.value;
        // } else if(othersInput.xray_others == 'xray_others') {
        //     ischecked.xray_others = e.target.value;
        // } else if(othersInput.ultrasound_others == 'ultrasound_others') {
        //     ischecked.ultrasound_others = e.target.value;
        // } else if(othersInput.ctscan_others == 'ctscan_others') {
        //     ischecked.ctscan_others = e.target.value;
        // } else if(othersInput.mri_others == 'mri_others') {
        //     ischecked.mri_others = e.target.value;
        // }
        // setIsChecked(ischecked)
        // setOthersInput({[e.target.name]: e.target.value})
    }

    // console.log(ischecked)

    useMemo(() => {
        if (othersInput.name == 'serum_others') {
            ischecked.serum_others = othersInput.serum_others;
            setOthersInput({ serum_others: othersInput.serum_others })
            setIsChecked(ischecked)
        } else
            if (othersInput.name == 'xray_others') {
                ischecked.xray_others = othersInput.xray_others;
                setOthersInput({ xray_others: othersInput.xray_others })
                setIsChecked(ischecked)
            } else
                if (othersInput.name == 'ultrasound_others') {
                    ischecked.ultrasound_others = othersInput.ultrasound_others;
                    setOthersInput({ ultrasound_others: othersInput.ultrasound_others })
                    setIsChecked(ischecked)
                } else
                    if (othersInput.name == 'ctscan_others') {
                        ischecked.ctscan_others = othersInput.ctscan_others;
                        setOthersInput({ ctscan_others: othersInput.ctscan_others })
                        setIsChecked(ischecked)
                    } else
                        if (othersInput.name == 'mri_others') {
                            ischecked.mri_others = othersInput.mri_others;
                            setOthersInput({ mri_others: othersInput.mri_others })
                            setIsChecked(ischecked)
                        }
        localStorage.setItem('LabRequestCheckBox', JSON.stringify(ischecked))
        // console.log(ischecked)
    }, [othersInput, ischecked])

    const saveLabRequest = async () => {


        var formData = new FormData();
        formData.append("save_lab_request", 1);
        formData.append("patient_id", onclickedpatient.patient_id);
        formData.append("lab_request", JSON.stringify(ischecked));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
        })
    }


    // const refClick = useRef()


    // function uncheckAll1() {
    //     var inputs = document.querySelectorAll('.check1');
    //     for (var i = 0; i < inputs.length; i++) {
    //       inputs[i].checked = false;
    //     }

    //     this.onclick = checkAll1; //function reference to original function
    //   }
    // console.log(ischecked)

    
    const containsWhitespace = (str) => {
        return /[&\/\\#,+()$~%.'":*?!<>{}]/g.test(str);
    }


    return (
        <div>
            <div className='row'>
                <div className={'col-sm-12'}>
                    <div style={{ padding: 5, backgroundColor: "#fff", textAlign: "center", fontWeight: "bolder", position: "relative", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ display: "flex", width: "50%", flexDirection: "row", gap: 10 }}>
                            <button onClick={() => onConsultationButton('')} style={{ height: 35, fontSize: window.innerWidth === 1024 ? 12 : window.innerWidth === 768 ? 12 : null, width: window.innerWidth === 768 ? 120 : "auto", borderRadius: 5, border: "3px solid #fff", backgroundColor: "red", color: "#fff", boxShadow: "-1px 2px 5px grey", fontWeight: 600 }}> Save & Return </button>
                            <button onClick={() => clearbtn()} style={{ height: 35, fontSize: window.innerWidth === 1024 ? 12 : window.innerWidth === 768 ? 12 : null, width: "auto", borderRadius: 5, border: "3px solid #fff", backgroundColor: "#c58007", color: "#fff", boxShadow: "-1px 2px 5px grey", fontWeight: 600 }}> Reset </button>
                        </div>
                        <div style={{ fontSize: window.innerWidth === 1024 ? 15 : window.innerWidth === 768 ? 15 : 25, width: "100%", textAlign: "left" }}>Laboratory | Diagnostic Request</div>
                        {/* <button onClick={() => saveLabRequest()} style={{ position: "absolute", height: 35, width: 150, right: 10, borderRadius: 5, border: "3px solid #fff", backgroundColor: "green", color: "#fff", boxShadow: "-1px 2px 5px grey", fontWeight: 600 }}> Save Request</button> */}
                    </div>
                    {/* <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"flex-start"}}>
                        <div style={{padding:5}}>
                            <div style={{fontWeight: "bold"}}>Profile test</div>
                        </div>
                        <div style={{padding:5}}>
                            <div style={{fontWeight: "bold"}}>Biochemistry</div>
                        </div>
                        <div style={{padding:5}}>
                            <div style={{fontWeight: "bold"}}>Hematology</div>
                            <div>
                                <span style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 2}}>
                                    <input type='checkbox' />
                                    CBC
                                </span>
                                <span style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 2}}>
                                    <input type='checkbox' />
                                    Platelet
                                </span>
                            </div>
                        </div>
                        <div style={{padding:5}}>
                            <div style={{fontWeight: "bold"}}>Microbiology</div>
                        </div>
                        <div style={{padding:5}}>
                            <div style={{fontWeight: "bold"}}>Anatomical Pathology</div>
                        </div>
                    </div> */}

                    <div className='hidescroll' style={{ height: "85vh", overflow: "auto", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", gap: 2 }}>
                        <div style={{ padding: 5, display: "flex", flexDirection: "row", gap: 10 }}>
                            <label style={{ display: "flex", fontWeight: ischecked?.cbc == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                <input type='checkbox' name='cbc' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.cbc} />
                                CBC
                            </label>
                            <label style={{ display: "flex", fontWeight: ischecked?.platelet == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                <input type='checkbox' name='platelet' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.platelet} />
                                Platelet
                            </label>
                            <label style={{ display: "flex", fontWeight: ischecked?.blood_type == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                <input type='checkbox' name='blood_type' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.blood_type} />
                                Blood Typing
                            </label>
                        </div>
                        <div style={{ padding: 5, width: "100%", backgroundColor: "#fff" }}>
                            <label style={{ display: "flex", fontWeight: ischecked?.serum == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                <input type='checkbox' name='serum' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum} />
                                Serum
                            </label>
                            {
                                ischecked?.serum == true ?
                                    <div style={{ border: "1px solid #fff", padding: 0 }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 15, padding: 5, border: "1px solid #fff" }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_creatinine == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_creatinine' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_creatinine} />
                                                Creatinine
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_bun == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_bun' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_bun} />
                                                BUN
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_uric_acid == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_uric_acid' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_uric_acid} />
                                                Uric Acid
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_alt == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_alt' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_alt} />
                                                ALT
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_ast == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_ast' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_ast} />
                                                AST
                                            </label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 15, padding: 5, border: "1px solid #fff" }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_na_plus == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_na_plus' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_na_plus} />
                                                Na+
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_k_plus == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_k_plus' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_k_plus} />
                                                K+
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_ca_plus2 == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_ca_plus2' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_ca_plus2} />
                                                Ca++
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_ica_plus2 == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_ica_plus2' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_ica_plus2} />
                                                ICa++
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_phos == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_phos' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_phos} />
                                                Phos
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_mg_plus2 == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_mg_plus2' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_mg_plus2} />
                                                Mg++
                                            </label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 15, padding: 5, border: "1px solid #fff" }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_lipitprofile == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_lipitprofile' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_lipitprofile} />
                                                Lipid Profile
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_fbs == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_fbs' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_fbs} />
                                                FBS
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_rbs == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_rbs' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_rbs} />
                                                RBS
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_hba1c == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_hba1c' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_hba1c} />
                                                HBA1c
                                            </label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 15, padding: 5, border: "1px solid #fff" }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_protime == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_protime' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_protime} />
                                                Protime
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_aptt == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_aptt' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_aptt} />
                                                APTT
                                            </label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 15, padding: 5, border: "1px solid #fff" }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_albumin == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_albumin' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_albumin} />
                                                Albumin
                                            </label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 15, padding: 5, border: "1px solid #fff" }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_troponin_i == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_troponin_i' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_troponin_i} />
                                                Troponin I
                                            </label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 15, padding: 5, border: "1px solid #fff" }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_abg == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_abg' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_abg} />
                                                ABG
                                            </label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10, padding: 5, border: "1px solid #fff" }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_bilirubin == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_bilirubin' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_bilirubin} />
                                                Bilirubin {ischecked?.serum_bilirubin == true ? ":" : ""}
                                            </label>
                                            {
                                                ischecked?.serum_bilirubin == true ?
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.serum_bilirubin_total == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='serum_bilirubin_total' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_bilirubin_total} />
                                                            Total
                                                        </label>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.serum_bilirubin_direct == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='serum_bilirubin_direct' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_bilirubin_direct} />
                                                            Direct
                                                        </label>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.serum_bilirubin_indirect == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='serum_bilirubin_indirect' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_bilirubin_indirect} />
                                                            Indirect
                                                        </label>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", paddingLeft: 10, padding: 5, border: "1px solid #fff" }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_alkaline_phosphatase == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_alkaline_phosphatase' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_alkaline_phosphatase} />
                                                Alkaline Phosphatase
                                            </label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", paddingLeft: 10, gap: 10, padding: 5, border: "1px solid #fff" }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_lipase == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_lipase' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_lipase} />
                                                Lipase
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_amylase == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_amylase' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_amylase} />
                                                Amylase
                                            </label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10, paddingLeft: 10, padding: 5, border: "1px solid #fff" }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_hbsAg == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_hbsAg' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_hbsAg} />
                                                HBsAg
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_anti_hav == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_anti_hav' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_anti_hav} />
                                                Anti-HAV
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_anti_hcv == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_anti_hcv' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_anti_hcv} />
                                                Anti-HCV
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_anti_hbs_titer_quantitative == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_anti_hbs_titer_quantitative' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_anti_hbs_titer_quantitative} />
                                                Anti-HBS Titer Quantitative
                                            </label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10, paddingLeft: 10, padding: 5, border: "1px solid #fff" }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_hiv == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_hiv' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_hiv} />
                                                HIV
                                            </label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10, paddingLeft: 10, padding: 5, border: "1px solid #fff" }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_tsh == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_tsh' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_tsh} />
                                                TSH
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_ft3 == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_ft3' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_ft3} />
                                                FT3
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_ft4 == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_ft4' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_ft4} />
                                                FT4
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_anti_tpo_antibodies == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_anti_tpo_antibodies' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_anti_tpo_antibodies} />
                                                Anti-TPO Antibodies
                                            </label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10, paddingLeft: 10, padding: 5, border: "1px solid #fff" }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_esr == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_esr' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_esr} />
                                                ESR
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_crp == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_crp' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_crp} />
                                                CRP
                                            </label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10, paddingLeft: 10, padding: 5, border: "1px solid #fff" }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_procalcitonin == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_procalcitonin' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_procalcitonin} />
                                                Procalcitonin
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_ferritin == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_ferritin' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_ferritin} />
                                                Ferritin
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_ldh == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_ldh' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_ldh} />
                                                LDH
                                            </label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10, paddingLeft: 10, padding: 5, border: "1px solid #fff" }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_ana_quantitative == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_ana_quantitative' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_ana_quantitative} />
                                                ANA Quantitative
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_dsdna == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_dsdna' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_dsdna} />
                                                DsDNA
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_c3_quantitative == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_c3_quantitative' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_c3_quantitative} />
                                                C3 Quantitative
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.serum_aso_titer == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='serum_aso_titer' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.serum_aso_titer} />
                                                ASO Titer
                                            </label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 2, paddingLeft: 10, padding: 5, border: "1px solid #ddd" }}>
                                            <span style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                Others: <input type='text' name='serum_others' onChange={(e) => setOthersInput({ ...othersInput, name: 'serum_others', [e.target.name]: e.target.value })} value={othersInput.serum_others != '' ? othersInput.serum_others : ischecked.serum_others} style={{ border: "none", width: 400, borderBottom: "1px solid #333", outline: "none", backgroundColor: "rgb(244 246 249)" }} />
                                                {
                                                    containsWhitespace(othersInput.serum_others) ? 
                                                    <span style={{color: "red"}}>Invalid</span>
                                                    :null
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                        <div style={{ padding: 5, width: "100%", backgroundColor: "#fff" }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", gap: 5 }}>
                                <label style={{ display: "flex", fontWeight: ischecked?.biomakers == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                    <input type='checkbox' name='biomakers' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers} />
                                    Biomarkers
                                    {/* {ischecked?.biomakers == true ? ": " : ""} */}
                                </label>
                                {
                                    ischecked?.biomakers == true ?
                                        <div style={{ border: "1px solid #fff", padding: 0 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                <label style={{ display: "flex", fontWeight: ischecked?.biomakers_breast == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                    <input type='checkbox' name='biomakers_breast' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_breast} />
                                                    Breast {ischecked?.biomakers_breast == true ? ": " : ""}
                                                </label>
                                                {
                                                    ischecked?.biomakers_breast == true ?
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_breast_brca_1 == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_breast_brca_1' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_breast_brca_1} />
                                                                BRCA1
                                                            </label>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_breast_brca_2 == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_breast_brca_2' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_breast_brca_2} />
                                                                BRCA2
                                                            </label>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_breast_ca_15_3 == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_breast_ca_15_3' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_breast_ca_15_3} />
                                                                CA 15-3
                                                            </label>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_breast_ca_27_29 == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_breast_ca_27_29' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_breast_ca_27_29} />
                                                                CA 27.29
                                                            </label>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_breast_cea == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_breast_cea' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_breast_cea} />
                                                                CEA
                                                            </label>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_breast_cea_125 == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_breast_cea_125' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_breast_cea_125} />
                                                                CEA 125
                                                            </label>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_breast_her2_neu == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_breast_her2_neu' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_breast_her2_neu} />
                                                                HER2/neu
                                                            </label>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                <label style={{ display: "flex", fontWeight: ischecked?.biomakers_ovarian == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                    <input type='checkbox' name='biomakers_ovarian' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_ovarian} />
                                                    Ovarian {ischecked?.biomakers_ovarian == true ? ": " : ""}
                                                </label>
                                                {
                                                    ischecked?.biomakers_ovarian == true ?
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_ovarian_afp == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_ovarian_afp' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_ovarian_afp} />
                                                                AFP
                                                            </label>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_ovarian_ca_125 == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_ovarian_ca_125' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_ovarian_ca_125} />
                                                                CA 125
                                                            </label>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                <label style={{ display: "flex", fontWeight: ischecked?.biomakers_thyroid == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                    <input type='checkbox' name='biomakers_thyroid' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_thyroid} />
                                                    Thyroid {ischecked?.biomakers_thyroid == true ? ": " : ""}
                                                </label>
                                                {
                                                    ischecked?.biomakers_thyroid == true ?
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_thyroid_thyroglobulin == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_thyroid_thyroglobulin' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_thyroid_thyroglobulin} />
                                                                Thyroglobulin
                                                            </label>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                <label style={{ display: "flex", fontWeight: ischecked?.biomakers_pancreatic == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                    <input type='checkbox' name='biomakers_pancreatic' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_pancreatic} />
                                                    Pancreatic {ischecked?.biomakers_pancreatic == true ? ": " : ""}
                                                </label>
                                                {
                                                    ischecked?.biomakers_pancreatic == true ?
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_pancreatic_ca_19_9 == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_pancreatic_ca_19_9' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_pancreatic_ca_19_9} />
                                                                CA 19-9
                                                            </label>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                <label style={{ display: "flex", fontWeight: ischecked?.biomakers_liver == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                    <input type='checkbox' name='biomakers_liver' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_liver} />
                                                    Liver {ischecked?.biomakers_liver == true ? ": " : ""}
                                                </label>
                                                {
                                                    ischecked?.biomakers_liver == true ?
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_liver_afp == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_liver_afp' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_liver_afp} />
                                                                AFP
                                                            </label>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                <label style={{ display: "flex", fontWeight: ischecked?.biomakers_colorectal == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                    <input type='checkbox' name='biomakers_colorectal' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_colorectal} />
                                                    Colorectal {ischecked?.biomakers_colorectal == true ? ": " : ""}
                                                </label>
                                                {
                                                    ischecked?.biomakers_colorectal == true ?
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_colorectal_cea == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_colorectal_cea' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_colorectal_cea} />
                                                                CEA
                                                            </label>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_colorectal_ca_19_9 == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_colorectal_ca_19_9' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_colorectal_ca_19_9} />
                                                                CA 19-9
                                                            </label>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_colorectal_fobt == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_colorectal_fobt' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_colorectal_fobt} />
                                                                FOBT
                                                            </label>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                <label style={{ display: "flex", fontWeight: ischecked?.biomakers_testicular == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                    <input type='checkbox' name='biomakers_testicular' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_testicular} />
                                                    Testicular {ischecked?.biomakers_testicular == true ? ": " : ""}
                                                </label>
                                                {
                                                    ischecked?.biomakers_testicular == true ?
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_testicular_hcg == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_testicular_hcg' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_testicular_hcg} />
                                                                hCG
                                                            </label>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_testicular_afp == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_testicular_afp' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_testicular_afp} />
                                                                AFP
                                                            </label>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                <label style={{ display: "flex", fontWeight: ischecked?.biomakers_prostate == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                    <input type='checkbox' name='biomakers_prostate' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_prostate} />
                                                    Prostate {ischecked?.biomakers_prostate == true ? ": " : ""}
                                                </label>
                                                {
                                                    ischecked?.biomakers_prostate == true ?
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_prostate_psa == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_prostate_psa' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_prostate_psa} />
                                                                PSA
                                                            </label>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                <label style={{ display: "flex", fontWeight: ischecked?.biomakers_germ_cell == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                    <input type='checkbox' name='biomakers_germ_cell' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_germ_cell} />
                                                    Germ Cell {ischecked?.biomakers_germ_cell == true ? ": " : ""}
                                                </label>
                                                {
                                                    ischecked?.biomakers_germ_cell == true ?
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_germ_cell_hcg == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_germ_cell_hcg' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_germ_cell_hcg} />
                                                                hCG
                                                            </label>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_germ_cell_afp == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_germ_cell_afp' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_germ_cell_afp} />
                                                                AFP
                                                            </label>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_germ_cell_ldh == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_germ_cell_ldh' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_germ_cell_ldh} />
                                                                LDH
                                                            </label>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                <label style={{ display: "flex", fontWeight: ischecked?.biomakers_cervical == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                    <input type='checkbox' name='biomakers_cervical' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_cervical} />
                                                    Cervical {ischecked?.biomakers_cervical == true ? ": " : ""}
                                                </label>
                                                {
                                                    ischecked?.biomakers_cervical == true ?
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                            <label style={{ display: "flex", fontWeight: ischecked?.biomakers_cervical_pap_smear == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                                <input type='checkbox' name='biomakers_cervical_pap_smear' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.biomakers_cervical_pap_smear} />
                                                                Pap Smear
                                                            </label>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        : null
                                }



                                {/* 
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                <label style={{ display: "flex", fontWeight: ischecked?.breast == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                    <input type='checkbox' name='breast' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.breast} />
                                                    Breast
                                                </label>
                                                <label style={{ display: "flex", fontWeight: ischecked?.ovarian == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                    <input type='checkbox' name='ovarian' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ovarian} />
                                                    Ovarian
                                                </label>
                                            </div> */}
                            </div>
                        </div>

                        <div style={{ padding: 5, width: "100%", backgroundColor: "#fff" }}>
                            <label style={{ display: "flex", fontWeight: ischecked?.urinalysis == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                <input type='checkbox' name='urinalysis' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.urinalysis} />
                                Urinalysis
                            </label>
                        </div>
                        <div style={{ padding: 5, width: "100%", backgroundColor: "#fff" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                <label style={{ display: "flex", fontWeight: ischecked?.stool_exam == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                    <input type='checkbox' name='stool_exam' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.stool_exam} />
                                    Stool Exam {ischecked?.stool_exam == true ? ": " : ""}
                                </label>
                                {
                                    ischecked?.stool_exam == true ?
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.conc_method == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='conc_method' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.conc_method} />
                                                Conc. Method
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.fobt == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='fobt' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.fobt} />
                                                FOBT
                                            </label>
                                        </div>
                                        : null
                                }

                            </div>
                        </div>

                        <div style={{ padding: 5, width: "100%", backgroundColor: "#fff" }}>
                            <label style={{ display: "flex", fontWeight: ischecked?.xray == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                <input type='checkbox' name='xray' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.xray} />
                                X-Ray
                            </label>
                            {
                                ischecked?.xray == true ?
                                    <div style={{ border: "1px solid #fff", padding: 0 }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.xray_chest == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='xray_chest' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.xray_chest} />
                                                Chest {ischecked?.xray_chest == true ? ": " : ""}
                                            </label>
                                            {
                                                ischecked?.xray_chest == true ?
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.xray_chest_pa == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='xray_chest_pa' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.xray_chest_pa} />
                                                            PA
                                                        </label>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.xray_chest_ap == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='xray_chest_ap' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.xray_chest_ap} />
                                                            AP
                                                        </label>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.xray_chest_lateral == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='xray_chest_lateral' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.xray_chest_lateral} />
                                                            Lateral
                                                        </label>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.xray_chest_apicolordotic == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='xray_chest_apicolordotic' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.xray_chest_apicolordotic} />
                                                            Apicolordotic
                                                        </label>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.xray_abdomen == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='xray_abdomen' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.xray_abdomen} />
                                                Abdomen {ischecked?.xray_abdomen == true ? ": " : ""}
                                            </label>
                                            {
                                                ischecked?.xray_abdomen == true ?
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.xray_abdomen_supine == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='xray_abdomen_supine' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.xray_abdomen_supine} />
                                                            Supine
                                                        </label>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.xray_abdomen_upright == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='xray_abdomen_upright' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.xray_abdomen_upright} />
                                                            Upright
                                                        </label>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.xray_spine == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='xray_spine' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.xray_spine} />
                                                Spine {ischecked?.xray_spine == true ? ": " : ""}
                                            </label>
                                            {
                                                ischecked?.xray_spine == true ?
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.xray_spine_cervical == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='xray_spine_cervical' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.xray_spine_cervical} />
                                                            Cervical
                                                        </label>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.xray_spine_cervico_thoracic == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='xray_spine_cervico_thoracic' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.xray_spine_cervico_thoracic} />
                                                            Cervico-thoracic
                                                        </label>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.xray_spine_thoracic == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='xray_spine_thoracic' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.xray_spine_thoracic} />
                                                            Thoracic
                                                        </label>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.xray_spine_thoracolumbar == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='xray_spine_thoracolumbar' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.xray_spine_thoracolumbar} />
                                                            Thoracolumbar
                                                        </label>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.xray_spine_lumbar == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='xray_spine_lumbar' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.xray_spine_lumbar} />
                                                            Thoracolumbar
                                                        </label>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.xray_foot == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='xray_foot' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.xray_foot} />
                                                Foot {ischecked?.xray_foot == true ? ": " : ""}
                                            </label>
                                            {
                                                ischecked?.xray_foot == true ?
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.xray_foot_apo_left == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='xray_foot_apo_left' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.xray_foot_apo_left} />
                                                            Left APO
                                                        </label>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.xray_foot_apo_right == true ? "bolder" : "normal", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='xray_foot_apo_right' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.xray_foot_apo_right} />
                                                            Right APO
                                                        </label>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 2, paddingLeft: 10, padding: 5, border: "1px solid #ddd" }}>
                                            <span style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                Others: <input name='xray_others' onChange={(e) => setOthersInput({ ...othersInput, name: 'xray_others', [e.target.name]: e.target.value })} value={othersInput.xray_others != '' ? othersInput.xray_others : ischecked.xray_others} style={{ border: "none", width: 400, borderBottom: "1px solid #333", outline: "none", backgroundColor: "rgb(244 246 249)" }} />
                                                {/* {console.log(containsWhitespace(othersInput.xray_others), othersInput.xray_others)} */}
                                                {
                                                    containsWhitespace(othersInput.xray_others) ? 
                                                    <span style={{color: "red"}}>Invalid</span>
                                                    :null
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>

                        <div style={{ padding: 5, width: "100%", backgroundColor: "#fff" }}>
                            <label style={{ display: "flex", fontWeight: ischecked?.ultrasound == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                <input type='checkbox' name='ultrasound' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ultrasound} />
                                Ultrasound
                            </label>
                            {
                                ischecked?.ultrasound == true ?
                                    <div style={{ border: "1px solid #fff", padding: 0 }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 15 }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.ultrasound_abdomen == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='ultrasound_abdomen' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ultrasound_abdomen} />
                                                Abdomen{ischecked?.ultrasound_abdomen == true ? ":" : null}
                                            </label>
                                            {
                                                ischecked?.ultrasound_abdomen == true ?
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.ultrasound_abdomen_whole == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='ultrasound_abdomen_whole' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ultrasound_abdomen_whole} />
                                                            Whole
                                                        </label>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.ultrasound_abdomen_upper == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='ultrasound_abdomen_upper' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ultrasound_abdomen_upper} />
                                                            Upper
                                                        </label>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.ultrasound_abdomen_lower == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='ultrasound_abdomen_lower' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ultrasound_abdomen_lower} />
                                                            Lower
                                                        </label>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.ultrasound_kub == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='ultrasound_kub' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ultrasound_kub} />
                                                KUB
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.ultrasound_kubp == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='ultrasound_kubp' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ultrasound_kubp} />
                                                KUBP
                                            </label>
                                        </div>
                                        <div>
                                            <label style={{ display: "flex", fontWeight: ischecked?.ultrasound_hepatobiliary == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='ultrasound_hepatobiliary' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ultrasound_hepatobiliary} />
                                                Hepatobiliary
                                            </label>
                                        </div>
                                        <div>
                                            <label style={{ display: "flex", fontWeight: ischecked?.ultrasound_thyroid == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='ultrasound_thyroid' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ultrasound_thyroid} />
                                                Thyroid
                                            </label>
                                        </div>
                                        <div>
                                            <label style={{ display: "flex", fontWeight: ischecked?.ultrasound_breast == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='ultrasound_breast' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ultrasound_breast} />
                                                Breast
                                            </label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.ultrasound_hemithorax_with_mapping == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='ultrasound_hemithorax_with_mapping' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ultrasound_hemithorax_with_mapping} />
                                                Hemithorax with Mapping
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.ultrasound_bilateral == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='ultrasound_bilateral' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ultrasound_bilateral} />
                                                Bilateral
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.ultrasound_left == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='ultrasound_left' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ultrasound_left} />
                                                Left
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.ultrasound_right == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='ultrasound_right' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ultrasound_right} />
                                                Right
                                            </label>
                                        </div>
                                        <div>
                                            <label style={{ display: "flex", fontWeight: ischecked?.ultrasound_testicular == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='ultrasound_testicular' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ultrasound_testicular} />
                                                Testicular
                                            </label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 2, paddingLeft: 10, padding: 5, border: "1px solid #ddd" }}>
                                            <span style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                Others: <input name='ultrasound_others' onChange={(e) => setOthersInput({ ...othersInput, name: 'ultrasound_others', [e.target.name]: e.target.value })} value={othersInput.ultrasound_others != '' ? othersInput.ultrasound_others : ischecked.ultrasound_others} style={{ border: "none", width: 400, borderBottom: "1px solid #333", outline: "none", backgroundColor: "rgb(244 246 249)" }} />
                                                {
                                                    containsWhitespace(othersInput.ultrasound_others) ? 
                                                    <span style={{color: "red"}}>Invalid</span>
                                                    :null
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>

                        <div style={{ padding: 5, width: "100%", backgroundColor: "#fff" }}>
                            <label style={{ display: "flex", fontWeight: ischecked?.ctscan == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                <input type='checkbox' name='ctscan' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ctscan} />
                                CT Scan
                            </label>
                            {
                                ischecked?.ctscan == true ?
                                    <div style={{ border: "1px solid #fff", padding: 0 }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 15 }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.ctscan_cranial == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='ctscan_cranial' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ctscan_cranial} />
                                                Cranial{ischecked?.ctscan_cranial == true ? ":" : null}
                                            </label>
                                            {
                                                ischecked?.ctscan_cranial == true ?
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.ctscan_cranial_plain == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='ctscan_cranial_plain' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ctscan_cranial_plain} />
                                                            Plain
                                                        </label>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.ctscan_cranial_contrast == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='ctscan_cranial_contrast' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ctscan_cranial_contrast} />
                                                            Contrast
                                                        </label>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.ctscan_cranial_angiography == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='ctscan_cranial_angiography' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ctscan_cranial_angiography} />
                                                            Angiography
                                                        </label>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 15 }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.ctscan_chest == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='ctscan_chest' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ctscan_chest} />
                                                Chest{ischecked?.ctscan_chest == true ? ":" : null}
                                            </label>
                                            {
                                                ischecked?.ctscan_chest == true ?
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.ctscan_chest_plain == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='ctscan_chest_plain' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ctscan_chest_plain} />
                                                            Plain
                                                        </label>
                                                        <label style={{ display: "flex", fontWeight: ischecked?.ctscan_chest_contrast == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                            <input type='checkbox' name='ctscan_chest_contrast' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ctscan_chest_contrast} />
                                                            Contrast
                                                        </label>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                            <label style={{ display: "flex", fontWeight: ischecked?.ctscan_abdomen == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='ctscan_abdomen' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ctscan_abdomen} />
                                                Abdomen
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.ctscan_plain == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='ctscan_plain' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ctscan_plain} />
                                                Plain
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.ctscan_triphasic_contrast == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='ctscan_triphasic_contrast' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ctscan_triphasic_contrast} />
                                                Triphasic Contrast
                                            </label>
                                            <label style={{ display: "flex", fontWeight: ischecked?.ctscan_triple_contrast == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                <input type='checkbox' name='ctscan_triple_contrast' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.ctscan_triple_contrast} />
                                                Triple Contrast
                                            </label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 2, paddingLeft: 10, padding: 5, border: "1px solid #ddd" }}>
                                            <span style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                Others: <input name='ctscan_others' onChange={(e) => setOthersInput({ ...othersInput, name: 'ctscan_others', [e.target.name]: e.target.value })} value={othersInput.ctscan_others != '' ? othersInput.ctscan_others : ischecked.ctscan_others} style={{ border: "none", width: 400, borderBottom: "1px solid #333", outline: "none", backgroundColor: "rgb(244 246 249)" }} />
                                                {
                                                    containsWhitespace(othersInput.ctscan_others) ? 
                                                    <span style={{color: "red"}}>Invalid</span>
                                                    :null
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>

                        <div style={{ padding: 5, width: "100%", backgroundColor: "#fff" }}>
                            <label style={{ display: "flex", fontWeight: ischecked?.MRI == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                <input type='checkbox' name='MRI' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.MRI} />
                                MRI
                            </label>
                            {
                                ischecked?.MRI == true ?
                                    <div style={{ border: "1px solid #fff", padding: 0 }}>
                                        <label style={{ display: "flex", fontWeight: ischecked?.MRI_Cranial == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                            <input type='checkbox' name='MRI_Cranial' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.MRI_Cranial} />
                                            Cranial
                                        </label>
                                        <label style={{ display: "flex", fontWeight: ischecked?.MRI_spinal == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                            <input type='checkbox' name='MRI_spinal' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.MRI_spinal} />
                                            Spinal
                                        </label>
                                        <label style={{ display: "flex", fontWeight: ischecked?.MRI_abdominal == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                            <input type='checkbox' name='MRI_abdominal' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.MRI_abdominal} />
                                            Abdominal
                                        </label>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 2, paddingLeft: 10, padding: 5, border: "1px solid #ddd" }}>
                                            <span style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                                Others: <input name='mri_others' onChange={(e) => setOthersInput({ ...othersInput, name: 'mri_others', [e.target.name]: e.target.value })} value={othersInput.mri_others != '' ? othersInput.mri_others : ischecked.mri_others} style={{ border: "none", width: 400, borderBottom: "1px solid #333", outline: "none", backgroundColor: "rgb(244 246 249)" }} />
                                                {
                                                    containsWhitespace(othersInput.mri_others) ? 
                                                    <span style={{color: "red"}}>Invalid</span>
                                                    :null
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                        <div style={{ padding: 5, width: "100%", backgroundColor: "#fff" }}>
                            <div>
                                <label style={{ display: "flex", fontWeight: ischecked?.twelve_leads_ecg == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                    <input type='checkbox' name='twelve_leads_ecg' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.twelve_leads_ecg} />
                                    12 Leads ECG
                                </label>
                            </div>
                            <div>
                                <label style={{ display: "flex", fontWeight: ischecked?.twoD_echo == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                    <input type='checkbox' name='twoD_echo' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.twoD_echo} />
                                    2D echo
                                </label>
                            </div>
                            <div>
                                <label style={{ display: "flex", fontWeight: ischecked?.cardiac_stress_test == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                    <input type='checkbox' name='cardiac_stress_test' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.cardiac_stress_test} />
                                    Cardiac Stress Test
                                </label>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 15 }}>
                                <label style={{ display: "flex", fontWeight: ischecked?.carotid_doppler_scan == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                    <input type='checkbox' name='carotid_doppler_scan' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.carotid_doppler_scan} />
                                    Carotid Doppler Scan{ischecked?.arterial_duplex_scan == true ? ":" : null}
                                </label>
                                {ischecked?.carotid_doppler_scan == true ?
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                        <label style={{ display: "flex", fontWeight: ischecked?.carotid_doppler_with_vertebral_scan == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                            <input type='checkbox' name='carotid_doppler_with_vertebral_scan' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.carotid_doppler_with_vertebral_scan} />
                                            with Vertebral Scan
                                        </label>
                                        <label style={{ display: "flex", fontWeight: ischecked?.carotid_doppler_without_vertebral_scan == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                            <input type='checkbox' name='carotid_doppler_without_vertebral_scan' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.carotid_doppler_without_vertebral_scan} />
                                            without Vertebral Scan
                                        </label>
                                    </div>
                                    : null
                                }
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 15 }}>
                                <label style={{ display: "flex", fontWeight: ischecked?.arterial_duplex_scan == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                    <input type='checkbox' name='arterial_duplex_scan' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.arterial_duplex_scan} />
                                    Arterial Duplex Scan{ischecked?.arterial_duplex_scan == true ? ":" : null}
                                </label>
                                {ischecked?.arterial_duplex_scan == true ?
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                        <label style={{ display: "flex", fontWeight: ischecked?.arterial_duplex_scan_bilateral == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                            <input type='checkbox' name='arterial_duplex_scan_bilateral' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.arterial_duplex_scan_bilateral} />
                                            Bilateral
                                        </label>
                                        <label style={{ display: "flex", fontWeight: ischecked?.arterial_duplex_scan_left == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                            <input type='checkbox' name='arterial_duplex_scan_left' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.arterial_duplex_scan_left} />
                                            Left
                                        </label>
                                        <label style={{ display: "flex", fontWeight: ischecked?.arterial_duplex_scan_right == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                            <input type='checkbox' name='arterial_duplex_scan_right' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.arterial_duplex_scan_right} />
                                            Right
                                        </label>
                                        <label style={{ display: "flex", fontWeight: ischecked?.arterial_duplex_scan_upper_extremity == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                            <input type='checkbox' name='arterial_duplex_scan_upper_extremity' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.arterial_duplex_scan_upper_extremity} />
                                            Upper Extremity
                                        </label>
                                        <label style={{ display: "flex", fontWeight: ischecked?.arterial_duplex_scan_lower_extremity == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                            <input type='checkbox' name='arterial_duplex_scan_lower_extremity' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.arterial_duplex_scan_lower_extremity} />
                                            Lower Extremity
                                        </label>
                                    </div>
                                    : null
                                }
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 15 }}>
                                <label style={{ display: "flex", fontWeight: ischecked?.venous_duplex_scan == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                    <input type='checkbox' name='venous_duplex_scan' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.venous_duplex_scan} />
                                    Venous Duplex Scan{ischecked?.venous_duplex_scan == true ? ":" : null}
                                </label>
                                {ischecked?.venous_duplex_scan == true ?
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                        <label style={{ display: "flex", fontWeight: ischecked?.venous_duplex_scan_bilateral == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                            <input type='checkbox' name='venous_duplex_scan_bilateral' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.venous_duplex_scan_bilateral} />
                                            Bilateral
                                        </label>
                                        <label style={{ display: "flex", fontWeight: ischecked?.venous_duplex_scan_left == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                            <input type='checkbox' name='venous_duplex_scan_left' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.venous_duplex_scan_left} />
                                            Left
                                        </label>
                                        <label style={{ display: "flex", fontWeight: ischecked?.venous_duplex_scan_right == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                            <input type='checkbox' name='venous_duplex_scan_right' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.venous_duplex_scan_right} />
                                            Right
                                        </label>
                                        <label style={{ display: "flex", fontWeight: ischecked?.venous_duplex_scan_upper_extremity == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                            <input type='checkbox' name='venous_duplex_scan_upper_extremity' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.venous_duplex_scan_upper_extremity} />
                                            Upper Extremity
                                        </label>
                                        <label style={{ display: "flex", fontWeight: ischecked?.venous_duplex_scan_lower_extremity == true ? "bolder" : "normal", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
                                            <input type='checkbox' name='venous_duplex_scan_lower_extremity' onChange={(e) => setIsChecked({ ...ischecked, [e.target.name]: e.target.checked })} checked={ischecked.venous_duplex_scan_lower_extremity} />
                                            Lower Extremity
                                        </label>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default LabReqForm;
