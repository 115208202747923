import React, { useEffect, useState } from 'react';
import MedicalRecord from './MedicalRecord';
import FollowupBtn from '../extra/FollowupBtn';
import VitalSingleHistory from './VitalSingleHistory';
import axios from 'axios';
import swal from 'sweetalert';
import ENV from '../ENV';

const PatientInfoPanel = (props) => {

    const position = sessionStorage.getItem('position');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    // patientlist, setPatientList

    const DoneBtn = async () => {
        var formData = new FormData();
        formData.append("patient_done_checkup", 1);
        formData.append("patient_id", props.onclickedpatient.patient_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            if (res.data === 'success') {
                // const index = props.patientlist.findIndex((i) => i.patient_id === props.onclickedpatient.patient_id);
                // props.patientlist[index].seen = 0;
                // props.setPatientList(props.patientlist)

                swal({
                    title: "Done!",
                    text: "Done",
                    icon: "success",
                    timer: 1000,
                    button: false,
                }).then(() => {
                    props.loadpatient()
                    props.onClickedPatient((prev) => ({ ...prev, seen: 0 }))
                    // window.location.assign("/")
                });
            }
            // console.log(res.data);
        })
    }

    useEffect(() => {

        window.addEventListener("resize", () => setWindowWidth(window.innerWidth)); //const [windowWidth, setWindowWidth] = useState(window.innerWidth)
        return () => window.removeEventListener("resize", () => setWindowWidth(window.innerWidth));
    }, [])

    return (
        <div style={{ height: "90vh" }}>
            {
                // windowWidth === 1080 ?
                <div style={{ padding: 10, borderBottom: "2px solid #ddd", backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 5, width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5, width: "100%" }}>
                            <div style={{ width: windowWidth === 1080 ? "40%" : windowWidth === 1920 ? "30%" : windowWidth === 1024 ? "40%" :  windowWidth === 768  ? "40%" : null }}>
                                <div style={{ fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : 18, fontWeight: 600 }}>{props.onclickedpatient.fullname}</div>
                                <div style={{ fontSize: windowWidth === 1024 ? 10 : windowWidth === 768 ? 10 : 15, display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center", gap: 10 }}>
                                    <span>{props.onclickedpatient.gender === 'male' ? 'Male' : 'Female'}</span>
                                    <span style={{ width: 5, height: 5, backgroundColor: "#ddd", borderRadius: 100 }}></span>
                                    <span>{props.onclickedpatient.age} years old</span>
                                </div>
                            </div>
                            <div style={{ width: windowWidth === 1080 ? "20%" : windowWidth === 1024 ? "20%" : windowWidth === 768 ? "20%" : null, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null }}>
                                <div>{props.onclickedpatient.contact}</div>
                                <div>{props.onclickedpatient.email_address}</div>
                                {/* {
                                    position === 'cashier' ?
                                        <FollowupBtn onclickedpatient={props.onclickedpatient} loadpatient={props.loadpatient} />
                                        : null
                                } */}
                            </div>
                        </div>
                        <div>
                        {
                            position === 'cashier' ?
                            (
                                props.onclickedpatient.seen !== "1" ? 
                                    <FollowupBtn onclickedpatient={props.onclickedpatient} loadpatient={props.loadpatient} label="Follow-up" />
                                :
                                (
                                    props.onclickedpatient.seen === "1" ? 
                                        <FollowupBtn onclickedpatient={props.onclickedpatient} loadpatient={props.loadpatient} label="Followed-up" />
                                    :null
                                )
                            )
                                : null
                        }
                        </div>
                    </div>
                    {
                        position != 'cashier' && position != 'admin_secretary' ?
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
                                {
                                    props.onclickedpatient.seen === "1" ? (
                                        <button onClick={DoneBtn} style={{ borderRadius: 5, border: "3px solid #fff", width: 100, height: windowWidth === 1024 ? 30 : 35, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null, backgroundColor: "#039bbe", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Complete</button>
                                    ) : (
                                        <></>
                                    )
                                }
                                <button onClick={() => props.onClickedButton('ConsultationForm')} style={{ borderRadius: 5, border: "3px solid #fff", width: windowWidth === 1024 ? 200 : 150, height: windowWidth === 1024 ? 30 : windowWidth === 768 ? 30 : 35, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null, backgroundColor: "blue", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Start Consultation</button>
                            </div>
                            : null
                    }
                </div>
                // :
                // <div style={{ padding: 10, borderBottom: "2px solid #ddd", backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                //     <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5 }}>
                //         <div style={{ width: 250 }}>
                //             <div style={{ fontSize: 18, fontWeight: 600 }}>{props.onclickedpatient.fullname}</div>
                //             <div style={{ fontSize: 15, display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center", gap: 10 }}>
                //                 <span>{props.onclickedpatient.gender === 'male' ? 'Male' : 'Female'}</span>
                //                 <span style={{ width: 5, height: 5, backgroundColor: "#ddd", borderRadius: 100 }}></span>
                //                 <span>{props.onclickedpatient.age} years old</span>
                //             </div>
                //         </div>
                //         <div style={{ width: 200 }}>
                //             <div>{props.onclickedpatient.contact}</div>
                //             <div>{props.onclickedpatient.email_address}</div>
                //         </div>
                // {
                //     position === 'cashier' ?
                //     <FollowupBtn onclickedpatient={props.onclickedpatient} loadpatient={props.loadpatient} />
                //     : null
                // }
                //     </div>
                //     {
                //         position != 'cashier' && position != 'admin_secretary' ?
                //         <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10}}>
                //         {
                //             props.onclickedpatient.seen === 1 ? (
                //                 <button onClick={DoneBtn} style={{ borderRadius: 5, border: "3px solid #fff", width: 100, height: 35, backgroundColor: "#039bbe", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Complete</button>
                //             ) : (
                //                 <></>
                //             )
                //         }
                //             <button onClick={()=>props.onClickedButton('ConsultationForm')} style={{ borderRadius: 5, border: "3px solid #fff", width: 180, height: 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Start Consultation</button>
                //         </div>
                //         :null
                //     }
                // </div>
            }
            <>
                <div style={{ padding: 10, borderBottom: "2px solid #ddd", backgroundColor: "#fff" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ fontSize: windowWidth === 1024 ? 15 : windowWidth === 768 ? 15 : 18, fontWeight: "bold" }}><i className='pi pi-user' /> Personal Info</div>
                        <div>
                            <button onClick={() => props.onClickedButton('EditPatientForm')} style={{ borderRadius: 5, border: "3px solid #fff", width: 180, height: windowWidth === 1024 ? 30 : windowWidth === 768 ? 30 : 35, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null, backgroundColor: "blue", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Edit Personal Info</button>
                        </div>
                    </div>
                </div>
                {
                    position === 'cashier' ?
                        <VitalSingleHistory onclickedpatient={props.onclickedpatient} onClickedButton={props.onClickedButton} />
                        : null
                }

            </>
            <div style={{ padding: 10, borderBottom: "2px solid #ddd", backgroundColor: "#fff" }}>
                <div>
                    {
                        position != 'cashier' ?
                            <div style={{ fontSize: windowWidth === 1024 ? 15 : windowWidth === 768 ? 15 : 18, fontWeight: "bold", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                <span><i className='pi pi-file' /> Medical Records</span>
                            </div>
                            :
                            <div style={{ fontSize: 18, fontWeight: "bold" }}><i className='pi pi-money-bill' /> Billing</div>
                    }
                </div>
            </div>
            <div>
                <MedicalRecord onclickedpatient={props.onclickedpatient} onclickedbutton={props.onclickedbutton} onClickedButton={props.onClickedButton} setResizePanel={props.setResizePanel} resizepanel={props.resizepanel} />
            </div>
        </div>
    );
}

export default PatientInfoPanel;
