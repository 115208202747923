import React, { useMemo, useState } from 'react';
import ENV from '../ENV';
import axios from 'axios';
import PrescriptionPDFViewer from '../extra/PrescriptionPDFViewer';
import MedicationCertPDFViewer from '../extra/MedicationCertPDFViewer';
import LabRequestFormView from '../extra/LabRequestFormView';
import DeleteConsultBtn from '../extra/DeleteConsultBtn';

const ConsultationHistory = ({ onclickedpatient }) => {

    const [trxlist, setTrxList] = useState([])

    useMemo(async () => {

        var formData = new FormData();
        formData.append("transaction_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setTrxList(res.data)
        })
    }, [ENV, setTrxList])


    let nums = 0;
    let one, two, three;
    const consultaionlist = trxlist.filter((i) => {
        return i.patient_id === onclickedpatient.patient_id;
    });

    // console.log(consultaionlist)

    const loadData = async () => {

        var formData = new FormData();
        formData.append("transaction_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setTrxList(res.data)
        })
    }

    const DataSet = (key, item) => {
        return (
            <div key={key} style={{ borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5}}>
                            <span style={{ fontSize: window.innerWidth === 1024 ? 15 : window.innerWidth === 768 ? 15 : 18, fontWeight: 600 }}>Consultations</span>
                            {
                                item.method == 'Input Amount' ?
                                    (
                                        item.status != 'paid' ?
                                            <span style={{ fontSize: 12, fontWeight: 500, color: "orange" }}>[Pending Payment]</span>
                                            : null
                                    )
                                    : null
                            }
                        </div>
                        <div style={{ fontSize: 18, fontWeight: 500 }}>
                            <DeleteConsultBtn item={item} loadData={loadData} />
                        </div>
                    </div>
                    <div style={{fontSize: window.innerWidth === 1024 ? 12 : window.innerWidth === 768 ? 12 : null}}>
                        <div><i className='pi pi-calendar' /> {item.date_time}</div>
                    </div>
                </div>
                <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                    <div style={{ paddingBottom: 5, fontWeight: "bolder", fontSize: window.innerWidth === 1024 ? 12 : window.innerWidth === 768 ? 12 : null }}>Issued Medical Documents</div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                        {
                            item.prescription.length > 0 && !item?.delete_doc?.pres_deleted ?
                                <>
                                    <PrescriptionPDFViewer item={item} />
                                </>
                                : null
                        }
                        {
                            item.med_cert.length > 0 && !item?.delete_doc?.med_cert_deleted ?
                                <MedicationCertPDFViewer item={item} />
                                : null
                        }
                        {
                            Object.keys(item.lab_request).length>0 && !item?.delete_doc?.lab_request_deleted ?
                                <LabRequestFormView item={item} />
                                : null
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {
                consultaionlist.length > 0 ?
                    <div className="hidescroll" style={{ display: "flex", flexDirection: "column", gap: 10, height: "100vh", overflow: "auto" }}>
                        {
                            consultaionlist.map((item, key) => {
                                // console.log(!item.delete_doc.lab_request_deleted, !item.delete_doc.med_cert_deleted, !item.delete_doc.pres_deleted)
                                if ((item.prescription).length>0 && item.delete_doc.pres_deleted!=true) {
                                    return DataSet(key, item)
                                } else
                                if ((item.med_cert).length>0 && item.delete_doc.med_cert_deleted!=true) {
                                    return DataSet(key, item)
                                } else 
                                if (Object.keys(item.lab_request).length>0 && item.delete_doc.lab_request_deleted!=true) {
                                    return DataSet(key, item)
                                }                                
                            })
                        }
                        <br />
                    </div>
                    : null
            }
        </>
    );
}

export default ConsultationHistory;
