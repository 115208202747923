import React, { useContext, useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';


import { ProgressSpinner } from 'primereact/progressspinner';
import PreSignUpListBtn from './component/dashboard/PreSignUpListBtn';
import Countdown from './extra/Countdown';
import { Skeleton } from 'primereact/skeleton';
import PhoneNumberSetup from './extra/PhoneNumberSetup';
import ChristmasBanner from './extra/ChristmasBanner';
import PrescriptionPDFViewer from './extra/PrescriptionPDFViewer';
import MedicationCertPDFViewer from './extra/MedicationCertPDFViewer';
import AppContext from './context/AppContext';
import Login from './Login';
import LabRequestFormView from './extra/LabRequestFormView';
import ReportsRow from './extra/ReportsRow';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Reports = ({ userInfo }) => {

    const [innerWidth, setInnerWidth] = useState(window.innerWidth)

    const { state, dispatch } = useContext(AppContext);
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed sidebar-collapse';

    const [trxlist, setTrxList] = useState([])

    useMemo(async () => {

        var formData = new FormData();
        formData.append("report_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setTrxList(res.data)
        })
    }, [ENV])

    const loadData = async () => {
        var formData = new FormData();
        formData.append("report_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setTrxList(res.data)
        })
    }


    const consultaionlist = trxlist.filter((i) => {
        return i.delete == 0;
    });


    const Display = () => {
        if (innerWidth === 1080) {
            return (
                <div className='col-sm-12'>
                    <div style={{ backgroundColor: "#fff", minHeight: "100vh" }}>
                        <table className='table table-bordered'>
                            <thead style={{ backgroundColor: "#ddd" }}>
                                <tr>
                                    <th style={{ maxWidth: 60 }}>No.</th>
                                    <th style={{ width: 150 }}>Date</th>
                                    <th>Name</th>
                                    <th>Medical Records</th>
                                    <th>Payment Details</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    consultaionlist.map((i, k) => {
                                        return (
                                            <ReportsRow key={k} k={k} i={i} loadData={loadData} />
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        } else if (innerWidth === 1920) {
            return (
                <div className='col-sm-12'>
                    <div>
                        <div>
                            <span>Today Sales: 0.00</span>
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#fff", minHeight: "100vh" }}>
                        <table className='table table-bordered'>
                            <thead style={{ backgroundColor: "#ddd" }}>
                                <tr>
                                    <th style={{ maxWidth: 60 }}>No.</th>
                                    <th style={{ width: 150 }}>Date</th>
                                    <th>Name</th>
                                    <th>Medical Records</th>
                                    <th>Payment Details</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    consultaionlist.map((i, k) => {
                                        return (
                                            <ReportsRow key={k} k={k} i={i} loadData={loadData} />
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        } else if (innerWidth === 1024) {
            return (
                <div className='col-sm-12'>
                    <div style={{ backgroundColor: "#fff", minHeight: "100vh" }}>
                        <table className='table table-bordered'>
                            <thead style={{ backgroundColor: "#ddd" }}>
                                <tr>
                                    <th style={{ maxWidth: 60, fontSize: 12 }}>No.</th>
                                    <th style={{ width: 150, fontSize: 12 }}>Date</th>
                                    <th style={{fontSize: 12}}>Name</th>
                                    <th style={{fontSize: 12}}>Medical Records</th>
                                    <th style={{fontSize: 12}}>Payment Details</th>
                                    <th style={{fontSize: 12}}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    consultaionlist.map((i, k) => {
                                        return (
                                            <ReportsRow key={k} k={k} i={i} loadData={loadData} />
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        } else if (innerWidth === 768) {
            return (
                <div style={{ marginTop: 10, width: "100%" }}>

                    <div style={{ display: "flex", flexDirection: "column", gap: 5, marginBottom: 55 }}>
                        {
                            consultaionlist.length > 0?
                            (
                                [...consultaionlist].map((i, k) => {
                                    return (
                                        <div style={{ padding: 5, borderRadius: 5, backgroundColor: "#fff", boxShadow: "0px 1px 2px grey" }}>
                                            <div><b>{i.fullname}</b></div>
                                            <div>
                                                {
                                                    i.method != 'Input Amount' ?
                                                        <b>{i.method}</b>
                                                        :
                                                        <>
                                                            {
                                                                i.status == 'paid' ?
                                                                    <>
                                                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                                                            <span style={{ color: "green", fontWeight: 600 }}>Paid</span>
                                                                            <span style={{ color: "#000", fontWeight: 600 }}>{'P ' + i.amount}</span>
                                                                        </div>
                                                                        {/* <div>
                                    <span>Amount: <b>{'P ' + i.amount}</b></span>
                                </div> */}
                                                                        {/* <div>
                                    <span>Tendered: <b>{'P.' + i.tendered}</b></span>
                                </div>
                                <div>
                                    <span>Changed: <b>{'P.' + i.change}</b></span>
                                </div> */}
                                                                    </>
                                                                    :
                                                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                                                        <span style={{ color: "#c35e18", fontWeight: 600 }}>Pending Payment</span>
                                                                        <span style={{ color: "#000", fontWeight: 600 }}>{'P ' + i.amount}</span>
                                                                    </div>
                                                            }
                                                        </>
                                                }
                                            </div>
                                            <div>{i.date_time}</div>
                                        </div>
                                    )
                                })
                            ) :
                            <>
                                <Skeleton width="100rem" height="4rem"></Skeleton>
                                <Skeleton width="100rem" height="4rem"></Skeleton>
                                <Skeleton width="100rem" height="4rem"></Skeleton>
                                <Skeleton width="100rem" height="4rem"></Skeleton>
                                <Skeleton width="100rem" height="4rem"></Skeleton>
                            </>
                        }
                    </div>
                </div>
            )
        }
    }

    // const num = 1;
    return (
        <div className="wrapper">
            {
                innerWidth === 768 || innerWidth === 1024 || innerWidth === 1080 || innerWidth === 1920 ?
                <Header />
                    :null
            }
            <Navbar userInfo={userInfo} />
            <div className="content-wrapper">
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {
                                Display()
                            }
                        </div>
                    </div>
                </section>
            </div>
            {
                innerWidth <= 750 ?
                    <div style={{ position: "fixed", height: 50, bottom: 0, width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", width: "100%" }}>
                            <div onClick={() => window.location.assign('/')} style={{ width: "50%", border: "1px solid #ddd", backgroundColor: "#fff", height: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>Home</div>
                            <div style={{ width: "50%", border: "1px solid #ddd", backgroundColor: "#fff", height: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>Account</div>
                        </div>
                    </div>
                    : null
            }
        </div>

    );
}

export default Reports;
