import React, { useRef, useState } from 'react';
import ENV from '../ENV';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import swal from 'sweetalert';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import PaymentConsultDailog from './PaymentConsultDailog';

const DeleteConsultBtn = ({ item, loadData }) => {
    const menuLeft = useRef(null);
    const menuRight = useRef(null);
    const toast = useRef(null);


    const [visible, setVisible] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [label, setLabel] = useState('');

    const DeleteData = async (i, label) => {

        if (label == 'Prescription') {
            item.delete_doc = {...item.delete_doc, "pres_deleted": true}
        } else if (label == 'Medical Certificate') {
            item.delete_doc = {...item.delete_doc, "med_cert_deleted": true}
        } else if (label == 'Lab Request') {
            item.delete_doc = {...item.delete_doc, "lab_request_deleted": true}
        } else if (label == 'all') {
            item.delete_doc = {"pres_deleted": true, "med_cert_deleted": true, "lab_request_deleted": true}
        }

        var formData = new FormData();
        formData.append("delete_record", 1);
        formData.append("record_id", i.id);
        formData.append("delete_record_status", 0);
        formData.append("delete_doc", JSON.stringify(item.delete_doc));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            if (res.data == 'success') {
                swal({
                    text: "Successfully Deleted",
                    icon: "success",
                    timer: 2000,
                    button: false,
                }).then(() => {
                    setOpenDelete(false)
                    loadData()
                });
            } else {

            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const newArray = []
    
    let nums =  0;

    const itemArray = (item) => {
        if (item.status != 'paid' && item.amount != '0.00') {
            newArray.push({
                label: 'Payments',
                icon: 'pi pi-money-bill',
                template: (itembtn, options) => {
                    return ( 
                        <li className="p-menuitem" role="none" data-pc-section="menuitem" style={{ backgroundColor: "rgb(0 109 59)" }}>
                            <a onClick={()=>setVisible(true)} className="p-menuitem-link" role="menuitem" tabIndex={0} data-pc-section="action">
                                <span className={`p-menuitem-icon ${itembtn.icon}`} data-pc-section="icon" style={{color: "#fff"}} /><span className="p-menuitem-text" data-pc-section="label" style={{ fontWeight: 600, color: "#fff" }}>{itembtn.label}</span>
                            </a>
                        </li> 
                    );
                },
                command: ()=>{
                    
                },
                separator: true
            })
        }
        if ((item.prescription).length>0 && item.delete_doc.pres_deleted !== true) {
            newArray.push({
                label: 'Prescription',
                icon: 'pi pi-trash',
                command: ()=>{
                    setOpenDelete(true)
                    setLabel('Prescription')
                    // DeleteData(item, 'Prescription')
                }
            })
            nums += 1;
        }
        if ((item.med_cert).length>0 && item.delete_doc.med_cert_deleted !== true) {
            newArray.push({
                label: 'Medical Certificate',
                icon: 'pi pi-trash',
                command: ()=>{
                    setOpenDelete(true)
                    setLabel('Medical Certificate')
                    // DeleteData(item, 'Medical Certificate')
                }
            })
            nums += 1;
        }
        if (Object.keys(item.lab_request).length>0 && item.delete_doc.lab_request_deleted !== true) {
            newArray.push({
                label: 'Lab Request',
                icon: 'pi pi-trash',
                command: ()=>{
                    setOpenDelete(true)
                    setLabel('Lab Request')
                    // DeleteData(item, 'Lab Request')
                }
            })
            nums += 1;
        }
        if (nums>1) {
            newArray.push({
                label: 'Delete All',
                icon: 'pi pi-trash',
                command: ()=>{
                    setOpenDelete(true)
                    setLabel('all')
                }
            })
        }
        
        return newArray

    }


    const items = [
        {
            label: 'Options',
            items: itemArray(item)
        }
    ];

    return (
        <>

            <Dialog header="Are you sure?" visible={openDelete} style={{ width: '30vw', padding: 5 }} onHide={() => setOpenDelete(false)}>
                <div style={{padding:5, textAlign: "center", fontWeight: 600}}>{label == 'all' ? 'Remove All Data' : label}</div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                    <button onClick={() => DeleteData(item, label)} style={{ borderRadius: 5, border: "3px solid #fff", width: 150, height: 35, backgroundColor: "green", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Yes</button>
                    <button onClick={() => setOpenDelete(false)} style={{ borderRadius: 5, border: "3px solid #fff", width: 150, height: 35, backgroundColor: "red", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>No</button>
                </div>
            </Dialog>
            
            <PaymentConsultDailog item={item} loadData={loadData} visible={visible} setVisible={setVisible} />  
            
            <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
            {/* <Button icon="pi pi-align-left" className="btn btn-xs" onClick={(event) => menuLeft.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup /> */}
            {/* <button onClick={() => setOpenDelete(true)} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10, alignItems: "center", border: "none", backgroundColor: "#fff" }}>
                <span style={{ fontSize: 15, color: "red" }}>Remove</span>
            </button> */}
            <button  onClick={(event) => menuLeft.current.toggle(event)} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10, alignItems: "center", border: "none", backgroundColor: "#fff" }}>
                <span className='pi pi-bars' style={{ fontSize: 15, color: "#000" }}></span>
            </button>
        </>
    );
}

export default DeleteConsultBtn;
