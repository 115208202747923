import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';

const PrescriptionFrequency = ({ setInputForm, inputform }) => {
    const [open, setOpen] = useState(false);
    const [custom, setCustom] = useState('');


    const frequency = [
        'Once a day',
        'Twice a day',
        '3 times a day',
        '4 times a day',
        '5 times a day',
        '6 times a day'
    ];

    const selectedFrequency = (item) => {
        // setInputForm({ ...inputform, dose: item })
        inputform.frequency = item;
        setInputForm(inputform)
        setCustom('')
    }

    const FrequencySelection = ({ inputform, setOpen }) => {
        return (
            <div onClick={(e) => setOpen(true)} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", padding: 10 }} >
                {inputform.frequency}
            </div>
        )
    }

    const submitFrequency = () => {
        let text;
        if (custom != 0 || custom != '') {
            inputform.frequency = custom;
            setInputForm(inputform)
            setOpen(false)
        }
    }

    return (
        <>
            <Dialog header="Select Frequency" visible={open} style={{ width: window.innerWidth === 768 ? '40vw' : '20vw' }} onHide={() => setOpen(false)}>
                <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                    {
                        frequency.map((item, key) => {
                            return (
                                <div key={key} onClick={(e) => { selectedFrequency(item); setOpen(false) }} style={{ width: "100%", padding: 5, borderRadius: 5, border: "1px solid #ddd", fontWeight: 600, cursor: "pointer" }}>{item}</div>
                            )
                        })
                    }
                </div>
                <div>
                    <div style={{ textAlign: "center", fontWeight: 600 }}>or</div>
                    <div style={{ textAlign: "center" }}>Enter custom frequency</div>

                    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", height: 40, borderRadius: 5, border: "1px solid #ddd" }}>
                            <input name='frequency' onChange={(e) => setCustom(e.target.value)} value={custom} style={{ width: "100%", textAlign: "left", height: 38, borderRadius: 5, border: "none", outline: "none", padding: 10 }} />
                        </div>
                        <div>
                            <button onClick={() => submitFrequency()} style={{ width: "100%", height: 35, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "green", color: "#fff", fontWeight: 600 }}>OK</button>
                        </div>
                    </div>

                </div>
            </Dialog>
            <FrequencySelection inputform={inputform} setOpen={setOpen} />
            {/* <input name='frequency' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", padding: 10 }} /> */}
        </>
    );
}

export default PrescriptionFrequency;
