import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ENV from '../ENV';
import swal from 'sweetalert';

const FollowupBtn = (props) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const onFollowup = async () => {
        console.log(props)
        // sample('asd555555asd')
        var formData = new FormData();
        formData.append("send_followup", 1);
        formData.append("patient_id", props.onclickedpatient.patient_id);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // swal({
            //     title: "Done!",
            //     text: "Successfully ",
            //     icon: "success",
            //     timer: 1000,
            //     button: false,
            // }).then(() => {
                props.loadpatient()
            // });
        });
    }
    useEffect(() => {

        window.addEventListener("resize", () => setWindowWidth(window.innerWidth)); //const [windowWidth, setWindowWidth] = useState(window.innerWidth)
        return () => window.removeEventListener("resize", () => setWindowWidth(window.innerWidth));
    }, [])


    return (
        <div>
            {
                props.label === 'Followed-up' ?
                <button style={{ borderRadius: 5, border: "3px solid #fff", width: 150, height: 35, backgroundColor: "#ddd", color: "#fff", fontWeight: "bold",fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null, boxShadow: "-1px 2px 5px grey" }}>{props.label}</button>
                :
                <button onClick={()=>onFollowup()} style={{ borderRadius: 5, border: "3px solid #fff", width: 150, height: 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold",fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null, boxShadow: "-1px 2px 5px grey" }}>{props.label}</button>
            }
        </div>
    );
}

export default FollowupBtn;
