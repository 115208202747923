import React, { useEffect, useMemo, useState } from 'react';
import ConsultationHistory from './Consultation_history';
import MedicationsHistory from './Medications_history';
import VitalSingleHistory from './VitalSingleHistory';
import VitalHistory from './Vital_history';
import ENV from '../ENV';
import axios from 'axios';
import ChartingForm from './ChartingForm';
import ConsultationInfront from './Consultation_infront';
import MedicationsInfront from './Medications_infront';
import LabReqForm from './LabReqForm';
import Billing from './Billing';
import ChartingInfront from './Charting_infront';

const MedicalRecord = ({ onclickedpatient, onclickedbutton, onClickedButton, setResizePanel, resizepanel }) => {
    const position = sessionStorage.getItem('position');
    const [MedicalRecordButton, setMedicalRecordButton] = useState('Vital History')
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    // const [historyview, setHistoryView] = useState('Consultation_history')

    const [trxlist, setTrxList] = useState([])

    useMemo(async () => {

        var formData = new FormData();
        formData.append("transaction_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setTrxList(res.data)
        })
    }, [ENV])
    // console.log(trxlist)
    useEffect(() => {

        window.addEventListener("resize", () => setWindowWidth(window.innerWidth)); //const [windowWidth, setWindowWidth] = useState(window.innerWidth)
        return () => window.removeEventListener("resize", () => setWindowWidth(window.innerWidth));
    }, [])

    if (MedicalRecordButton == 'Lab Request Form') {
        return (
            <LabReqForm onclickedpatient={onclickedpatient} setMedicalRecordButton={setMedicalRecordButton} />
        )
    }

    // alert(windowWidth)

    

    return (
        <div>
            <div style={{display: "flex", flexDirection: windowWidth === 768 ? "column" : "row"}}>
                {
                    position != 'cashier' ?
                    (
                        
                            windowWidth === 768 ?
                            <div>
                                <div className='' style={{ height: 50, width: "100%", backgroundColor: "#fff", padding: 10, display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5 }}>
                                    <div onClick={() => { setMedicalRecordButton('Vital History'); setResizePanel(null) }} style={{ cursor: "pointer", width: "auto", backgroundColor: MedicalRecordButton == 'Vital History' ? "#adadff" : "#fff", justifyContent: "flex-start", display: "flex", flexDirection: "row", alignItems: "center", padding: 5, borderRadius: 5, fontWeight: MedicalRecordButton == 'Vital History' ? 600 : 500, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null }}>Vital History</div>
                                    <div onClick={() => { setMedicalRecordButton('Charting'); setResizePanel('col-sm-2') }} style={{ cursor: "pointer", width: "auto", backgroundColor: MedicalRecordButton == 'Charting' ? "#adadff" : "#fff", justifyContent: "flex-start", display: "flex", flexDirection: "row", alignItems: "center", padding: 5, borderRadius: 5, fontWeight: MedicalRecordButton == 'Charting' ? 600 : 500, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null }}>Charting</div>
                                    <div onClick={() => { setMedicalRecordButton('Recent'); setResizePanel(null) }} style={{ cursor: "pointer", width: "auto", backgroundColor: MedicalRecordButton == 'Recent' ? "#adadff" : "#fff", justifyContent: "flex-start", display: "flex", flexDirection: "row", alignItems: "center", padding: 5, borderRadius: 5, fontWeight: MedicalRecordButton == 'Recent' ? 600 : 500, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null }}>Recent Overall History</div>
                                    {/* <div onClick={() => { setMedicalRecordButton('Lab Request Form'); setResizePanel(null) }} style={{ cursor: "pointer", width: "100%", backgroundColor: MedicalRecordButton == 'Lab Request Form' ? "#adadff" : "#fff", justifyContent: "flex-start", display: "flex", flexDirection: "row", alignItems: "center", padding: 5, borderRadius: 5, fontWeight: MedicalRecordButton == 'Lab Request Form' ? 600 : 500, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null }}>Lab Request Form</div> */}
                                    <div onClick={() => { setMedicalRecordButton('Consultation History'); setResizePanel(null) }} style={{ cursor: "pointer", width: "auto", backgroundColor: MedicalRecordButton == 'Consultation History' ? "#adadff" : "#fff", justifyContent: "flex-start", display: "flex", flexDirection: "row", alignItems: "center", padding: 5, borderRadius: 5, fontWeight: MedicalRecordButton == 'Consultation History' ? 600 : 500, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null }}>Consultation History</div>
                                    {/* <div onClick={() => { setMedicalRecordButton('Medication History'); setResizePanel(null) }} style={{ cursor: "pointer", width: "100%", backgroundColor: MedicalRecordButton == 'Medication History' ? "#adadff" : "#fff", justifyContent: "flex-start", display: "flex", flexDirection: "row", alignItems: "center", padding: 5, borderRadius: 5, fontWeight: MedicalRecordButton == 'Medication History' ? 600 : 500, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null }}>Medication History</div> */}
                                </div>
                            </div>
                            :
                            <div className={windowWidth === 1366 ? 'col-sm-12' : 'col-sm-3'}>
                                <div className='' style={{ height: windowWidth === 1366 ? null : "75vh", width: "100%", backgroundColor: "#fff", padding: 10, display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: 5 }}>
                                    <div onClick={() => { setMedicalRecordButton('Vital History'); setResizePanel(null) }} style={{ cursor: "pointer", width: windowWidth === 1366 ? "auto" : "100%", backgroundColor: MedicalRecordButton == 'Vital History' ? "#adadff" : "#fff", justifyContent: "flex-start", display: "flex", flexDirection: "row", alignItems: "center", padding: 5, borderRadius: 5, fontWeight: MedicalRecordButton == 'Vital History' ? 600 : 500, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null }}>Vital History</div>
                                    <div onClick={() => { setMedicalRecordButton('Charting'); setResizePanel('col-sm-2') }} style={{ cursor: "pointer", width: windowWidth === 1366 ? "auto" : "100%", backgroundColor: MedicalRecordButton == 'Charting' ? "#adadff" : "#fff", justifyContent: "flex-start", display: "flex", flexDirection: "row", alignItems: "center", padding: 5, borderRadius: 5, fontWeight: MedicalRecordButton == 'Charting' ? 600 : 500, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null }}>Charting</div>
                                    <div onClick={() => { setMedicalRecordButton('Recent'); setResizePanel(null) }} style={{ cursor: "pointer", width: windowWidth === 1366 ? "auto" : "100%", backgroundColor: MedicalRecordButton == 'Recent' ? "#adadff" : "#fff", justifyContent: "flex-start", display: "flex", flexDirection: "row", alignItems: "center", padding: 5, borderRadius: 5, fontWeight: MedicalRecordButton == 'Recent' ? 600 : 500, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null }}>Recent Overall History</div>
                                    {/* <div onClick={() => { setMedicalRecordButton('Lab Request Form'); setResizePanel(null) }} style={{ cursor: "pointer", width: "100%", backgroundColor: MedicalRecordButton == 'Lab Request Form' ? "#adadff" : "#fff", justifyContent: "flex-start", display: "flex", flexDirection: "row", alignItems: "center", padding: 5, borderRadius: 5, fontWeight: MedicalRecordButton == 'Lab Request Form' ? 600 : 500, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null }}>Lab Request Form</div> */}
                                    <div onClick={() => { setMedicalRecordButton('Consultation History'); setResizePanel(null) }} style={{ cursor: "pointer", width: windowWidth === 1366 ? "auto" : "100%", backgroundColor: MedicalRecordButton == 'Consultation History' ? "#adadff" : "#fff", justifyContent: "flex-start", display: "flex", flexDirection: "row", alignItems: "center", padding: 5, borderRadius: 5, fontWeight: MedicalRecordButton == 'Consultation History' ? 600 : 500, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null }}>Consultation History</div>
                                    {/* <div onClick={() => { setMedicalRecordButton('Medication History'); setResizePanel(null) }} style={{ cursor: "pointer", width: "100%", backgroundColor: MedicalRecordButton == 'Medication History' ? "#adadff" : "#fff", justifyContent: "flex-start", display: "flex", flexDirection: "row", alignItems: "center", padding: 5, borderRadius: 5, fontWeight: MedicalRecordButton == 'Medication History' ? 600 : 500, fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null }}>Medication History</div> */}
                                </div>
                            </div>

                        
                    )
                        : null
                }
                <div className={position != 'cashier' ? "col-sm-9" : 'col-sm-12'}>
                    {
                        position != 'cashier' ?
                            <div style={{ display: "flex", flexDirection: "column", marginTop: 15, gap: 10, height: "75vh" }}>
                                {
                                    MedicalRecordButton == 'Recent' ?
                                        <>
                                            <ChartingInfront onclickedpatient={onclickedpatient} />
                                            <ConsultationInfront onclickedpatient={onclickedpatient} setMedicalRecordButton={setMedicalRecordButton} />
                                            {/* <MedicationsInfront onclickedpatient={onclickedpatient} setMedicalRecordButton={setMedicalRecordButton} /> */}
                                            <VitalSingleHistory onclickedpatient={onclickedpatient} onClickedButton={onClickedButton} setMedicalRecordButton={setMedicalRecordButton} />
                                        </>
                                        : null
                                }
                                {
                                    MedicalRecordButton == 'Charting' ?
                                        <ChartingForm onclickedpatient={onclickedpatient} />
                                        :
                                        null
                                }
                                {
                                    MedicalRecordButton == 'Consultation History' ?
                                        <ConsultationHistory onclickedpatient={onclickedpatient} />
                                        // <ConsultationInfront onclickedpatient={onclickedpatient} setMedicalRecordButton={setMedicalRecordButton} />
                                        : null
                                }
                                {
                                    MedicalRecordButton == 'Medication History' ?
                                        // <MedicationsInfront onclickedpatient={onclickedpatient} setMedicalRecordButton={setMedicalRecordButton} />
                                        <MedicationsHistory onclickedpatient={onclickedpatient} />
                                        : null
                                }
                                {
                                    MedicalRecordButton == 'Vital History' ?
                                        <VitalSingleHistory onclickedpatient={onclickedpatient} onClickedButton={onClickedButton} setMedicalRecordButton={setMedicalRecordButton} />
                                        : null
                                }
                                {
                                    MedicalRecordButton == 'Vital_history_list' ?
                                        <VitalHistory onclickedpatient={onclickedpatient} onClickedButton={onClickedButton} setMedicalRecordButton={setMedicalRecordButton} />
                                        : null
                                }

                                {
                                    MedicalRecordButton == 'Consultation_history' ?
                                        <ConsultationHistory onclickedpatient={onclickedpatient} />
                                        : null
                                }
                                {
                                    MedicalRecordButton == 'Medication_history' ?
                                        <MedicationsHistory onclickedpatient={onclickedpatient} />
                                        : null
                                }
                            </div>
                            : 
                            <div>
                                <div>
                                    <Billing onclickedpatient={onclickedpatient} />
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default MedicalRecord;
