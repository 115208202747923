import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import ENV from '../ENV';

const VitalSingleHistory = ({ onclickedpatient, onClickedButton, setMedicalRecordButton }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const position = sessionStorage.getItem('position');

    const [vitaldetails, setVitalDetails] = useState(null)
    
    useMemo(async () => {
        var formData = new FormData();
        formData.append("show_current_vitals", 1);
        formData.append("patient_id", onclickedpatient.patient_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data[0])
            setVitalDetails(res.data[0])
        })
    }, [onclickedpatient, ENV])
    useEffect(() => {

        window.addEventListener("resize", () => setWindowWidth(window.innerWidth)); //const [windowWidth, setWindowWidth] = useState(window.innerWidth)
        return () => window.removeEventListener("resize", () => setWindowWidth(window.innerWidth));
    }, [])

    return (
        <div style={{ borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff" }}>
            <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ fontSize: windowWidth === 1024 ? 15 : windowWidth === 768 ? 15 : 18, fontWeight: 600 }}>Vital</div>
                    <div style={{ fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : 18, fontWeight: 500 }}>
                        {
                            position != 'cashier' ?
                            <button onClick={() => setMedicalRecordButton('Vital_history_list')} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10, alignItems: "center", border: "none", backgroundColor: "#fff" }}>
                                <span style={{fontSize: windowWidth === 1024 ? 15 : windowWidth === 768 ? 15 : null}}>View History</span>
                                <span className='pi pi-arrow-right' />
                            </button>
                            :null
                        }
                    </div>
                </div>
                <div>
                    <div style={{fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : null}}><i className='pi pi-calendar' /> {vitaldetails?.date_time}</div>
                </div>
            </div>
            {
                vitaldetails ?
                <div style={{ padding: 10 }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                        <div style={{ width: "50%" }}>
                            <div style={{ fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : 15, fontWeight: 600 }}>Weight</div>
                            <div style={{fontSize: windowWidth === 1024 ? 10 : null}}>{vitaldetails?.weight}</div>
                        </div>
                        <div style={{ width: "50%" }}>
                            <div style={{ fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : 15, fontWeight: 600 }}>Height</div>
                            <div style={{fontSize: windowWidth === 1024 ? 10 : null}}>{vitaldetails?.height}</div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                        <div style={{ width: "50%" }}>
                            <div style={{ fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : 15, fontWeight: 600 }}>Blood Type</div>
                            <div style={{fontSize: windowWidth === 1024 ? 10 : null}}>{vitaldetails?.blood_type}</div>
                        </div>
                        <div style={{ width: "50%" }}>
                            <div style={{ fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : 15, fontWeight: 600 }}>Temperature</div>
                            <div style={{fontSize: windowWidth === 1024 ? 10 : null}}>{vitaldetails?.tepm}</div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                        <div style={{ width: "50%" }}>
                            <div style={{ fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : 15, fontWeight: 600 }}>Systolic</div>
                            <div style={{fontSize: windowWidth === 1024 ? 10 : null}}>{vitaldetails?.systolic}</div>
                        </div>
                        <div style={{ width: "50%" }}>
                            <div style={{ fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : 15, fontWeight: 600 }}>Diastolic</div>
                            <div style={{fontSize: windowWidth === 1024 ? 10 : null}}>{vitaldetails?.diastolic}</div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                        <div style={{ width: "50%" }}>
                            <div style={{ fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : 15, fontWeight: 600 }}>Heart Rate</div>
                            <div style={{fontSize: windowWidth === 1024 ? 10 : null}}>{vitaldetails?.heart_rate}</div>
                        </div>
                        <div style={{ width: "50%" }}>
                            <div style={{ fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : 15, fontWeight: 600 }}>Respiration Rate</div>
                            <div style={{fontSize: windowWidth === 1024 ? 10 : null}}>{vitaldetails?.respiration_rate}</div>
                        </div>
                    </div>
                </div>
                : null
            }
            <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    <button onClick={() => onClickedButton('AddVitalForm')} style={{ fontSize: windowWidth === 1024 ? 12 : windowWidth === 768 ? 12 : 18, height: windowWidth === 1024 ? 30 : windowWidth === 768 ? 30 : 40, borderRadius: 5, backgroundColor: "#62afd7", border: "3px solid #fff", color: "#fff", fontWeight: 600, width: windowWidth === 1024 ? 110 : 150, boxShadow: "-1px 2px 5px grey" }}>Take Vitals</button>
                </div>
            </div>
        </div>
    );
}

export default VitalSingleHistory;
