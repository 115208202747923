import { BrowserRouter as Router, Route, Link, useRouteMatch, useParams, Switch } from "react-router-dom";
import React from 'react';
// import Homepage from "./components/Homepage";
import Login from './daclinic/Login'
import Dashboard from "./daclinic/Dashboard";
import axios from "axios";
import ENV from "./daclinic/ENV";
import { useEffect, useMemo, useState } from "react";
import Patient from "./daclinic/Patient";
import Transactions from "./daclinic/Transactions";
import Calendar from "./daclinic/Calendar";
import { AppProvider } from "./daclinic/context/AppContext";
import Reports from "./daclinic/Reports";
// import firebase from "./firebase";
import io from "socket.io-client";

import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { format } from "date-fns";
import PosLogin from "./pos/Login";
import PosDashboard from "./pos/Dashboard";
import PosProduct from "./pos/Product";
import PosCashier from "./pos/Cashier";

import dir from 'C:\\Windows\\System32\\drivers\\etc\\react_register.txt';
import { ProgressSpinner } from "primereact/progressspinner";
import Statistics from "./daclinic/Statistics";

function App() {
    // localStorage.removeItem("computerKey")

    disableReactDevTools();      
    // const socket = io.connect("http://192.168.1.11:3001");

    const position = sessionStorage.getItem('position');

    const [computerAddress, setComputerAddress] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [notification, setNotification] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [timeout, settimeout] = useState(0);
    const [newtransaction, setNewTransaction] = useState([]) 

    const [windowWidth, setWindowWidth] = useState(0);
    // const [windowHeight, setWindowHeight] = useState(0);
    // let resizeWindow = () => {
    //   setWindowWidth(window.innerWidth);
    //   setWindowHeight(window.innerHeight);
    // };

    useEffect(()=>{  

        fetch(dir)
        .then(row => row.text())
        .then(text => {
            // setComputerAddress(text)
            if (text !== localStorage.getItem("computerKey")) {
                // console.log('exit'+text)
                localStorage.setItem("computerKey", text);
                return;
            }
        });

            // window.addEventListener("resize", ()=>{setWindowWidth(window.innerWidth)});
            // return () => window.removeEventListener("resize", ()=>{setWindowWidth(window.innerWidth)});
        
    }, [dir])

    setTimeout(() => {
        setisLoading(true)
    }, 1000);

    // alert(windowWidth)

    // console.log(window.innerWidth)

    return (
        <AppProvider>
            <Router>
                <Switch>
                    <Route exact={true} path="/">
                        {

                            sessionStorage.getItem('username') === null ?
                                isLoading ?
                                    (
                                        <Login />
                                    )
                                    :
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                                        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                        <span>Please wait...</span>
                                    </div>
                                :
                                <Patient userInfo={userInfo} newtransaction={newtransaction} />
                        }
                    </Route>
                    <Route exact={true} path="/dashboard">
                        {sessionStorage.getItem('username') === null ? <Login /> : <Dashboard userInfo={userInfo} />}
                    </Route>
                    <Route exact={true} path="/patient">
                        {
                            sessionStorage.getItem('username') === null ?
                                isLoading ?
                                    (
                                        <Login />
                                    )
                                    :
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                                        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                        <span>Please wait...</span>
                                    </div>
                                :
                                (

                                    position == 'master_admin' || position == 'admin_doctor' || position == 'admin_secretary' ?
                                        <Patient userInfo={userInfo} newtransaction={newtransaction} />
                                        : null
                                )
                        }
                    </Route>
                    <Route exact={true} path="/calendar">
                        {sessionStorage.getItem('username') === null ? <Login /> : <Calendar userInfo={userInfo} />}
                    </Route>
                    <Route exact={true} path="/vitals">
                        {sessionStorage.getItem('username') === null ? <Login /> : <Dashboard userInfo={userInfo} />}
                    </Route>
                    <Route exact={true} path="/medication">
                        {sessionStorage.getItem('username') === null ? <Login /> : <Dashboard userInfo={userInfo} />}
                    </Route>
                    <Route exact={true} path="/consultation">
                        {sessionStorage.getItem('username') === null ? <Login /> : <Dashboard userInfo={userInfo} />}
                    </Route>
                    <Route exact={true} path="/billing">
                        {
                            position == 'master_admin' || position == 'admin_doctor' || position == 'admin_secretary' || position == 'cashier' ?
                                sessionStorage.getItem('username') === null ?
                                    isLoading ?
                                        (
                                            <Login />
                                        )
                                        :
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                            <span>Please wait...</span>
                                        </div>
                                    :
                                    <Dashboard userInfo={userInfo} />
                                : null
                        }
                    </Route>
                    <Route exact={true} path="/transaction">
                        {
                            position == 'master_admin' || position == 'admin_doctor' || position == 'admin_secretary' ?
                                sessionStorage.getItem('username') === null ?
                                    isLoading ?
                                        (
                                            <Login />
                                        )
                                        :
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                            <span>Please wait...</span>
                                        </div>
                                    :
                                    <Transactions userInfo={userInfo} />
                                : null
                        }
                    </Route>
                    <Route exact={true} path="/report">
                        {
                            position == 'master_admin' || position == 'admin_doctor' ?
                                sessionStorage.getItem('username') === null ?
                                    isLoading ?
                                        (
                                            <Login />
                                        )
                                        :
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                            <span>Please wait...</span>
                                        </div>
                                    :
                                    <Reports userInfo={userInfo} />
                                : null
                        }
                    </Route>
                    <Route exact={true} path="/report/statistics">
                        {
                            position == 'master_admin' || position == 'admin_doctor' ?
                                sessionStorage.getItem('username') === null ?
                                    isLoading ?
                                        (
                                            <Login />
                                        )
                                        :
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                            <span>Please wait...</span>
                                        </div>
                                    :
                                    <Statistics userInfo={userInfo} />
                                : null
                        }
                    </Route>

                    <Route exact={true} path="/pos">
                        <PosLogin />
                    </Route>

                    <Route exact={true} path="/pos/dashboard">
                        <PosDashboard />
                    </Route>

                    <Route exact={true} path="/pos/product">
                        <PosProduct />
                    </Route>

                    <Route exact={true} path="/pos/cashier">
                        <PosCashier/>
                    </Route>
                </Switch>
            </Router>
        </AppProvider>
    )
}

export default App;
